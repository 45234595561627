import React, {useEffect, useState} from "react";
import Button from "../../components/v2/Button";
import StepperCar from "../../components/v2/StepperCar";
import Input from "../../components/v2/Input";
import useValetStore from "./hooks/useValetStore";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    PaymentElement, useElements,
    useStripe
} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import Swal from "sweetalert2";
import {PaymentType} from "../../constants/PaymentType";
import ValetPaymentStripe from "./ValetPaymentStripe";

export default function ValetPaymentStripeWrapper({
                                                      searchParams
                                                  }) {
    const getStripePK = () => {
        if (process.env.REACT_APP_STRIPE_TEST_MODE == "true") {
            return process.env.REACT_APP_STRIPE_PK_TEST;
        } else {
            return process.env.REACT_APP_STRIPE_PK;
        }
    };

    const [stripePromise, setStripePromise] = useState(() => loadStripe(getStripePK()))

    const elementsOptions = {
        mode: 'payment',
        currency: 'chf',
        amount: 1099, // TODO , c'est géré par le serveur, le rappelé aussi ici ?
        appearance: {
            theme: 'night',
            variables: {
                // https://docs.stripe.com/elements/appearance-api?platform=web#commonly-used-variables
            }
        },
    };

    return (
        <Elements stripe={stripePromise} options={elementsOptions}>
            <ValetPaymentStripe searchParams={searchParams}/>
        </Elements>)
}