import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {
    Box,
    Container,
    Grid,
    TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {useOutletContext, useParams} from "react-router-dom";
import '../App.css';
import PictureUpload from "./PictureUpload";

import DefaultImage from "../assets/menu/vehicle_add/add-vehicle-white-black.png";
import useFetch from "../hooks/useFetch";
import MobileContext from "../contexts/mobileContext";
import PictureUploadMini from "./PictureUploadMini";

function VehicleStep2({
                          previous_btn,
                          next_btn,
                          changeStepFromContentHandler,
                          contentLoadedHandler,
                          isEditMode,
                          standaloneVehicleId = null
                      }, ref) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');
    const formRef = React.useRef();
    const [vehicleId, setVehicleId] = useState(null);
    const [vehicleData, setVehicleData] = useState(null);

    // Does vin / brand / model / carCategory locked
    const [locked, setLocked] = useState(false);
    const {call, callPost} = useFetch();

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    let params = useParams();

    useEffect(() => {
        if (standaloneVehicleId != null) {
            getFromServer(standaloneVehicleId);
        } else {
            getFromServer(params.vehicleId);
        }


        previous_btn.current.style.display = 'none';
        next_btn.current.addEventListener('click', submitData);

        return () => {
            next_btn.current && next_btn.current.removeEventListener('click', submitData);
        }

    }, [])

    function submitData() {
        if (!formRef.current.reportValidity()) {
            return;
        }
        formRef.current.dispatchEvent(
            new Event("submit", {cancelable: true, bubbles: true})
        );
    }

    useImperativeHandle(ref, () => ({
        wantChangeTab(stepValue) {
            // TODO SAve or warn..
            changeStepFromContentHandler(stepValue);
        },
    }));

    // TODO redondant
    async function getFromServer(id) {
        call(apiUri + 'vehicle_get/' + id, (resultData) => {
            setVehicleId(resultData.vehicle.id);
            setVehicleData(resultData.vehicle);
            setLocked(resultData.vehicle.is_check_vin_decoder == '1')
            contentLoadedHandler(resultData.vehicle);
        });
    }

    //
    async function sendToServer(data) {
        callPost(apiUri + 'vehicle_register_step2', data, (resultData) => {
            Swal.fire({
                text: t('vehicle.form.success'),
                icon: 'success',
                timer: 2000,
                target: document.getElementById('swal_container'),
            })
                .then(() => {
                    if (!isEditMode) {
                        changeStepFromContentHandler(-1)
                    }
                });
        });
    }


    function submitHandler(event) {
        if (!formRef.current.reportValidity()) {
            return;
        }

        // TODO vérifier la taille des textes
        let data = {
            id: vehicleId,
            first_date_traffic: formRef.current.elements['first_date_traffic'].value,
            licence_plate: formRef.current.elements['licence_plate'].value,
            motor: formRef.current.elements['motor'].value,
            color: formRef.current.elements['color'].value,
            main_driver: formRef.current.elements['main_driver'].value,
        }
        sendToServer(data);
    }

    function licencePlateInputHandler(event) {
        // Alpha + tiret
        let value = event.target.value;
        const ALPHA_NUMERIC_REGEX = /[^a-zA-Z0-9-]/gi;
        let remplaced = value.replace(ALPHA_NUMERIC_REGEX, '');
        remplaced = remplaced.toUpperCase();
        event.target.value = remplaced;
    }

    return (
        <Container>
            {vehicleData != null &&
            <>
                <form className="form-inline" onSubmit={submitHandler} ref={formRef}>
                    <Grid container>
                        {!isMobile &&
                        <Grid item xs={12} sm={6} sx={{p: '16px'}}>
                            <Box display="flex" justifyContent="center"
                                 sx={{
                                     border: 2,
                                     borderRadius: '8px',
                                     borderColor: 'orange',
                                     p: '8px',
                                     color: 'orange'
                                 }}>
                                <b>{t('vehicle.form.pictures_label')}</b>
                            </Box>
                            <Grid container justifyContent="center" sx={{mt: '8px'}}>
                                <PictureUpload
                                    picture_uri={vehicleData.picture}
                                    default_picture={DefaultImage}
                                    api_uri="vehicle_upload_picture"
                                    model_id={vehicleData.id}
                                    width="90%"
                                    height="auto"
                                />
                            </Grid>
                        </Grid>
                        }
                        <Grid item sx={12} sm={6} sx={{p: '16px'}}>
                            {!isMobile &&
                            <Box display="flex" justifyContent="center"
                                 sx={{
                                     border: 2,
                                     borderRadius: '8px',
                                     borderColor: 'orange',
                                     p: '8px',
                                     color: 'orange'
                                 }}>
                                <b>{t('vehicle.form.info_label')}</b>
                            </Box>
                            }
                            <Box display="flex" justifyContent="left"
                                 sx={{
                                     border: 2,
                                     borderRadius: '8px',
                                     borderColor: 'orange',
                                     p: '8px',
                                     mt: '8px',
                                     color: 'orange'
                                 }}>
                                {vehicleData.brand}
                            </Box>
                            <Box display="flex" justifyContent="left"
                                 sx={{
                                     border: 2,
                                     borderRadius: '8px',
                                     borderColor: 'orange',
                                     p: '8px',
                                     mt: '8px',
                                     color: 'orange'
                                 }}>
                                {vehicleData.model}
                            </Box>

                            <TextField
                                margin="normal"
                                fullWidth
                                id="licence_plate"
                                required
                                label={t('vehicle.form.registration_number_label')}
                                onInput={licencePlateInputHandler}
                                defaultValue={vehicleData.licence_plate}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="vin"
                                disabled={locked}
                                label={t('vehicle.form.vin')}
                                defaultValue={vehicleData.vin}
                                disabled={true}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="car_category"
                                label={t('vehicle.form.car_category')}
                                defaultValue={vehicleData?.category?.name_long}
                                disabled={true}
                            />
                            <TextField
                                InputLabelProps={{shrink: true}}
                                // TODO Will not work
                                // inputProps={{
                                //     lang: 'en-GB'
                                // }}
                                margin="normal"
                                fullWidth
                                id="first_date_traffic"
                                type="date"
                                label={t('vehicle.form.first_traffic_date_label')}
                                defaultValue={vehicleData.first_date_traffic}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="motor"
                                label={t('vehicle.form.motor_label')}
                                defaultValue={vehicleData.motor}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="color"
                                label={t('vehicle.form.color_label')}
                                defaultValue={vehicleData.color}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="main_driver"
                                label={t('vehicle.form.main_driver_label')}
                                defaultValue={vehicleData.main_driver}
                                inputProps={{maxLength: 255}}
                            />
                        </Grid>

                        {isMobile &&
                        <Grid container justifyContent="center" sx={{mt: '8px'}}>
                            <PictureUploadMini
                                picture_uri={vehicleData.picture}
                                default_picture={DefaultImage}
                                api_uri="vehicle_upload_picture"
                                model_id={vehicleData.id}
                                width="90%"
                                height="auto"
                            />
                        </Grid>
                        }

                    </Grid>
                </form>
            </>
            }
        </Container>
    )
};

export default forwardRef(VehicleStep2);