import React, {useEffect, useState} from "react";
import Button from "../../components/v2/Button";
import StepperCar from "../../components/v2/StepperCar";
import Input from "../../components/v2/Input";
import IconFrom from "../../assets/v2/map-pin.png";
import IconTo from "../../assets/v2/goal.png";
import Dot from "../../assets/v2/dot.png";
import useValetStore from "./hooks/useValetStore";
import Swal from "sweetalert2";
import RadioSelector from "../../components/v2/RadioSelector";
import {RouteType} from "./constants/RouteType";
import {AccountType} from "../../constants/AccountType";
import {ValetSteps} from "./constants/ValetSteps";

export default function ValetYourIdentity({
                                              backClickedHandler
                                          }) {

    const {
        callPost,
        userData,
        setIdentityData,
        setStep
    } = useValetStore();

    const [isCompany, setIsCompany] = useState((userData.identity.company !== null && userData.identity.company !== ''));
    const [company, setCompany] = useState(userData?.identity?.company ?? '');
    const [lastName, setLastName] = useState(userData?.identity?.last_name ?? '');
    const [firstName, setFirstName] = useState(userData?.identity?.first_name ?? '');
    const [email, setEmail] = useState(userData?.email ?? '');
    const [phone, setPhone] = useState(userData?.identity?.phone ?? '');

    const formRef = React.useRef();

    const nextClickedHandler = (e) => {
        if (!formRef.current.reportValidity()) {
            return;
        }
        callPost('account_details', {
            'last_name': lastName,
            'first_name': firstName,
            'phone': phone,
            'company': company
        }, (data) => {
            setIdentityData(
                {
                    last_name: lastName,
                    first_name: firstName,
                    phone: phone,
                    company: company
                }
            )
            setStep(
                ValetSteps.YOUR_ORDER
            );
        }, (failResultData) => {
            Swal.fire({
                text: failResultData.message,
                icon: 'error',
            });
        });
    }

    return <div className="h-full flex flex-col p-4 gap-4">
        <StepperCar value={60}/>
        <div className="text-white text-[30px] font-bold">
            Votre êtes ?
        </div>
        <form ref={formRef}>


            <RadioSelector version={"button"} id={"route_type"} value={isCompany} values={[
                {value: false, label: 'Particulier'},
                {value: true, label: 'Entreprise'},
            ]} onChange={(value) => setIsCompany(value)}/>

            <fieldset className="border border-solid border-gray-300 p-3 rounded-2xl mt-4">
                <legend className="text-sm">&nbsp;IDENTITE&nbsp;</legend>
                {isCompany &&
                    <Input id="company" label="Dénomination sociale (nom de votre entreprise)"
                           placeholder="Exemple : PARADISE Inc."
                           value={company}
                           onChange={(e) => setCompany(e)}
                           required={true}
                    />
                }
                <Input id="last_name" label="Nom" placeholder="Exemple : Jackson"
                       value={lastName}
                       onChange={(e) => setLastName(e)}
                       required={true}
                />
                <Input id="first_name" label="Prénom" placeholder="Exemple : Mickael"
                       value={firstName}
                       onChange={(e) => setFirstName(e)}
                       required={true}
                />
            </fieldset>

            <fieldset className="border border-solid border-gray-300 p-3 rounded-2xl mt-4">
                <legend className="text-sm">&nbsp;CONTACTS&nbsp;</legend>
                <Input id="email" label="Adresse électronique"
                       placeholder="Exemple : m.jackson@gmail.com"
                       value={email}
                       disabled={true}
                />
                <Input id="phone" label="Numéro de téléphone" placeholder="Exemple : 06.12.34.56.78"
                       value={phone}
                       onChange={(e) => setPhone(e)}
                       required={true}
                />
            </fieldset>
        </form>
        <div className="grow">
        </div>
        <div className="flex gap-4">
            <Button outline text={<div>
                <div>Précédent</div>
                <div className="text-sm mt-[-8px]">(véhicule)</div>
            </div>} clickHandler={backClickedHandler}/>
            <Button text="Suivant" clickHandler={nextClickedHandler}/>
        </div>
    </div>
}