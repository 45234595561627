import {create} from 'zustand'
import {devtools} from "zustand/middleware";

const UseFetch = () => {
    // const [accessToken, setAccessToken] = useLocalStorageState('accessToken');
    //const [isSending, setIsSending] = useState(false);

    const call = (
        url,
        callBack,
        method = 'GET',
        body = null,
        jsonBody = false,
        failCallback = null,
        accessToken = null) => {
        // setIsSending(true);
        let headers = {
            'Accept': 'application/json'
        };

        if (accessToken) {
            headers['Authorization'] = 'Bearer ' + accessToken;
        }

        if (body == null) {
            headers['Content-Type'] = 'application/json';
        } else {
            if (jsonBody) {
                headers['Content-Type'] = 'application/json';
                body = JSON.stringify(body);
            }
        }


        fetch(url, {
            method: method,
            headers: headers,
            body: body
        })
            .then(response => {
                // setIsSending(false);
                if (response.status == 401) {
                    // Swal.fire({
                    //     text: t('common.error.not_connected'),
                    //     icon: 'error',
                    //     target: document.getElementById('swal_container'),
                    // });
                    // setAccessToken(null);
                    // // TODO setAccessToken isn't enough ( doesn't "dispatch" new value )
                    // navigate('/login');
                    console.log("ERROR 401");
                    return null;
                }
                return response.json()
            })
            .then(data => {
                if (data != null) {
                    if (data.state === 'OK') {
                        callBack.call(this, data);
                    } else {
                        if (failCallback != null) {
                            failCallback.call(this, data);
                        } else {
                            // Swal.fire({
                            //     text: t(data.message),
                            //     icon: 'error',
                            //     target: document.getElementById('swal_container')
                            // });
                            console.log("ERROR KO");
                        }
                    }
                }
            })
            .catch(rejected => {
                console.log('REJECTED : ', url, rejected);
                // Swal.fire({
                //     text: t('common.error.connexion_error'),
                //     icon: 'error',
                //     target: document.getElementById('swal_container')
                // });
            });
    }

    const callPost = (url, json = null, callBack, failCallBack = null, accessToken) => {
        call(url, callBack, 'POST', json, true, failCallBack, accessToken);
    }

    const callPostForm = (url, data = null, callBack, failCallBack = null, accessToken) => {
        call(url, callBack, 'POST', data, false, failCallBack, accessToken);
    }
    return {call, callPost, callPostForm};  // callWithParams
}

// TODO Peut-etre séparer dans un autre store tout ce qui concerne les appels API

const initialState = {
    accessToken: null,
    servicesBaseUrl: null,
    token: null,
    loading: 0,
    step: null,
    valetIntent: null,
    userData: {
        email: null,
        has_password: true,
        paymentType: null,
        confirmationData: {
            email: null,
            key: null
        },
        identity: {
            company: null,
            last_name: null,
            first_name: null,
            phone: null
        },
        travel: {
            fromAddress: null,
            fromCp: null,
            fromCity: null,
            toAddress: null,
            toCp: null,
            toCity: null,
            routeType: null,
            date: null,
            time: null,
            retrieveHour: null,
            phone: null,
            comment: null,
            deliveryContactName: null,
            deliveryContactPhone: null,
        },
        vehicle: {
            brand: null,
            model: null,
            licence_plate: null,
            vehicle: null   // Objet vehicule de l'api , avec un id
        },
    }
}

const useValetStore = create(
    devtools((set) => ({
            ...initialState,
            setAccessToken: (accessToken) => {
                set({accessToken: accessToken});
            },
            setLoading: (loading) => {
                set({loading: loading});
            },
            setStep: (step) => {
                set({step: step});
            },
            setUserData: (userData) => {
                set(state => ({
                    userData: userData
                }));
            },
            setTravelData: (travelData) => {    // Utilisé lors de la recherche de trajet

                const newTravel = {
                    ...useValetStore.getState().userData.travel,
                    ...travelData
                }

                set(state => ({
                    userData: {
                        ...useValetStore.getState().userData,
                        ...{
                            travel: newTravel
                        }
                    }
                }));
            },
            setVehicleData: (vehicleData) => {
                set(state => ({
                    userData: {
                        ...useValetStore.getState().userData,
                        ...{
                            vehicle: vehicleData
                        }
                    }
                }));
            },
            setIdentityData: (identityData) => {
                set(state => ({
                    userData: {
                        ...useValetStore.getState().userData,
                        ...{
                            identity: identityData
                        }
                    }
                }));
            },
            setStatusData: (statusData) => {    // Utilisé lors de l'interogation du valet_only_status

                console.log("statusData.account.has_password", statusData.account.has_password);
                console.log("statusData", statusData);

                set(state => ({
                    valetIntent: statusData.valet_intent,
                    // On merge
                    userData: {
                        ...useValetStore.getState().userData,
                        ...{
                            email: statusData.account.email,
                            has_password: statusData.account.has_password,
                            travel: {
                                fromAddress: statusData.valet_intent.origin_address,
                                fromCp: statusData.valet_intent.origin_zip_code,
                                fromCity: statusData.valet_intent.origin_city,
                                toAddress: statusData.valet_intent.destination_address,
                                toCp: statusData.valet_intent.destination_zip_code,
                                toCity: statusData.valet_intent.destination_city,
                                routeType: statusData.valet_intent.route_type
                            }
                        }
                    }
                }));
            },
            setEmail: (email) => {
                set(state => ({
                    userData: {
                        email: email
                    }
                }));
            },
            setConfirmationData: (confirmationData) => {
                set(state => ({
                    userData: {
                        confirmationData: confirmationData
                    }
                }));
            },
            callPost: async (endpoint, data, callback, callbackFailed) => {
                const customFetch = UseFetch();
                set(state => ({loading: state.loading + 1}));
                console.log("useValetStore.getState().accessToken", useValetStore.getState().accessToken);
                customFetch.callPost(useValetStore.getState().servicesBaseUrl + endpoint, data, (resultData) => {
                    set(state => ({
                        loading: state.loading - 1
                    }));
                    callback(resultData);
                }, (failResultData) => {
                    set(state => ({
                        loading: state.loading - 1
                    }));
                    callbackFailed(failResultData);
                }, useValetStore.getState().accessToken);
            },
            callPostForm: async (endpoint, data, callback, callbackFailed) => {
                const customFetch = UseFetch();
                set(state => ({loading: state.loading + 1}));
                customFetch.callPostForm(useValetStore.getState().servicesBaseUrl + endpoint, data, (resultData) => {
                    set(state => ({
                        loading: state.loading - 1
                    }));
                    callback(resultData);
                }, (failResultData) => {
                    set(state => ({
                        loading: state.loading - 1
                    }));
                    callbackFailed(failResultData);
                }, useValetStore.getState().accessToken);
            },
            callGet: async (endpoint, callback, callbackFailed) => {
                const customFetch = UseFetch();
                set(state => ({loading: state.loading + 1}));
                customFetch.call(useValetStore.getState().servicesBaseUrl + endpoint, (resultData) => {
                    set(state => ({
                        loading: state.loading - 1
                    }));
                    callback(resultData);
                }, 'GET', null, null, (failResultData) => {
                    set(state => ({
                        loading: state.loading - 1
                    }));
                    callbackFailed(failResultData);
                }, useValetStore.getState().accessToken);
            },
        }),
        {
            name: 'valet-store',
        })
)

export default useValetStore;