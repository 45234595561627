import React, {useEffect} from "react";

interface InputProps {
    id: string;
    label: string;
    value: string;
    type?: string;
    readonly: boolean;
    onChange: (value: string) => void;
    disabled?: boolean;
    required?: boolean;
}

export default function Input({
                                  id,
                                  label,
                                  value,
                                  onChange,
                                  placeholder,
                                  type = "input",
                                  disabled = false,
                                  required = false,
                                  pattern = null,
                                  ...rest // Collect remaining props
                              }: InputProps) {
    return (
        <div {...rest}>
            <label htmlFor={id}
                   className="block w-full text-sm font-medium leading-6 text-white">{label}</label>
            {type === 'textarea' ?
                <textarea
                    className="block p-2.5 w-full text-sm text-gray-900 disabled:opacity-65 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    disabled={disabled}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    required={required}
                >
                </textarea> :
                <input
                    placeholder={placeholder}
                    className="block p-2.5 w-full text-sm text-white-900 disabled:opacity-65 bg-white bg-opacity-10 rounded-lg border border-gray-500 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    disabled={disabled}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    required={required}
                    type={type}
                    pattern={pattern}
                >
                </input>
            }
        </div>);
}