import React from "react";

import CarIcon from "../../assets/v2/car.png";
import Success from "../../assets/v2/success_inverse.png";

export default function StepperCar({
                                       value,
                                       ...rest
                                   }) {

    if (value === 100) {
        return (
            <div className="w-full bg-[#00ae11] rounded-[20px] h-[10px] flex gap-2 justify-center">
                <div><img className="-mt-4" src={Success} alt="car" width={"40px"}/></div>
            </div>
        );
    }

    return (
        <div className="w-full bg-black rounded-[20px] h-[10px] flex gap-2">
            <div className="bg-[#f9b712] rounded-[20px] h-[10px]" style={{width: value + "%"}}/>
            <div><img className="-mt-2" src={CarIcon} alt="car"/></div>
        </div>
    );
}