import React, {useEffect, useState} from "react";
import Button from "../../components/v2/Button";
import StepperCar from "../../components/v2/StepperCar";
import Input from "../../components/v2/Input";
import useValetStore from "./hooks/useValetStore";
import {PaymentType} from "../../constants/PaymentType";
import Swal from "sweetalert2";
import {ValetSteps} from "./constants/ValetSteps";

export default function ValetPaymentInvoice({}) {

    const {
        callPost,
        userData,
        valetIntent,
        setStep
    } = useValetStore();

    const [invoiceCostCenter, setInvoiceCostCenter] = useState('');
    const [invoiceName, setInvoiceName] = useState('');
    const [invoiceAddress, setInvoiceAddress] = useState('');
    const [invoicePostalCode, setInvoicePostalCode] = useState('');
    const [invoiceCity, setInvoiceCity] = useState('');

    const formRef = React.useRef();

    const payClickedHandler = function (e) {
        if (!formRef.current.reportValidity()) {
            return;
        }
        // TODO Pas mal redondant avec ValetPaymentStripe
        callPost('valet_only_order_intent', {
            'valet_intent_id': valetIntent.id,
            'vehicle_id': userData.vehicle.vehicle.id,
            'date': userData.travel.date,
            'time': userData.travel.time,
            'retrieve_hour': userData.travel.retrieveHour,
            'phone': userData.travel.phone,
            'delivery_contact_name': userData.travel.deliveryContactName,
            'delivery_contact_phone': userData.travel.deliveryContactPhone,
            'payment_type_id': PaymentType.INVOICE,
            'invoice_name': invoiceName,
            'invoice_cost_center': invoiceCostCenter,
            'invoice_address': invoiceAddress,
            'invoice_postal_code': invoicePostalCode,
            'invoice_city': invoiceCity
        }, (data) => {
            setStep(ValetSteps.ORDER_DONE);
        }, (failResultData) => {
            Swal.fire({
                text: failResultData.message,
                icon: 'error',
            });
        });
    }

    return <div className="h-full flex flex-col p-4 gap-4">
        <StepperCar value={90}/>
        <div className="text-white text-[30px] font-bold">
            Informations facturation
        </div>
        <form ref={formRef}>
            <fieldset className="border border-solid border-gray-300 p-3 rounded-2xl mt-4">
                <legend className="text-sm">&nbsp;DETAILS&nbsp;</legend>
                <Input id="brand" label="Centre de coût" placeholder=""
                       value={invoiceCostCenter}
                       required={true}
                       onChange={(e) => setInvoiceCostCenter(e)}
                />
                <Input id="brand" label="Nom de facturation" placeholder=""
                       value={invoiceName}
                       required={true}
                       onChange={(e) => setInvoiceName(e)}
                />
                <Input id="brand" label="Adresse de facturation" placeholder=""
                       value={invoiceAddress}
                       required={true}
                       onChange={(e) => setInvoiceAddress(e)}
                />
                <Input id="brand" label="NIP" placeholder=""
                       value={invoicePostalCode}
                       required={true}
                       onChange={(e) => setInvoicePostalCode(e)}
                />
                <Input id="brand" label="Ville" placeholder=""
                       value={invoiceCity}
                       required={true}
                       onChange={(e) => setInvoiceCity(e)}
                />
            </fieldset>
        </form>
        <div className="grow">
        </div>

        <Button text="Valider la commande" clickHandler={payClickedHandler}/>
        <Button outline text="Précédent" clickHandler={(e) => setStep(ValetSteps.SUMMARY)}/>
    </div>
}