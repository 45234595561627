import React, {useEffect} from "react";
import Button from "../../components/v2/Button";
import StepperCar from "../../components/v2/StepperCar";
import useValetStore from "./hooks/useValetStore";
import {
    PaymentElement, useElements,
    useStripe
} from "@stripe/react-stripe-js";
import Swal from "sweetalert2";
import {PaymentType} from "../../constants/PaymentType";
import {ValetSteps} from "./constants/ValetSteps";
import Price from "../../components/v2/Price";

export default function ValetPaymentStripe({
                                               searchParams = null
                                           }) {

    const stripe = useStripe();
    const elements = useElements();
    const [show, setShow] = React.useState(false);

    const {
        callPost,
        valetIntent,
        userData,
        setStep,
        loading,
        setLoading,
    } = useValetStore();

    useEffect(() => {
        if (searchParams.get('payment_intent') != null) {
            // On vérifie / valide le payment
            callPost('valet_only_verify_payment', {
                'stripe_payment_intent_id': searchParams.get('payment_intent'),
            }, (data) => {
                setLoading(loading - 1);
                setStep(ValetSteps.ORDER_DONE);
            }, (failResultData) => {
                Swal.fire({
                    text: failResultData.message,
                    icon: 'error',
                });
            });
        } else {
            setShow(true);
        }
    }, [searchParams]);

    const payClickedHandler = function (e) {
        e.preventDefault();


        // Trigger form validation and wallet collection
        elements.submit().then((result) => {
            console.log("submitResult", result);
            if (result.error) {
                return; // elements affiche de lui-même les erreurs
            }

            setLoading(loading + 1);    // On encapsule toute la démarche dans un loading

            // Etape 1 on créer l'intent
            callPost('valet_only_order_intent', {
                'valet_intent_id': valetIntent.id,
                'vehicle_id': userData.vehicle.vehicle.id,
                'date': userData.travel.date,
                'time': userData.travel.time,
                'retrieve_hour': userData.travel.retrieveHour,
                'phone': userData.travel.phone,
                'delivery_contact_name': userData.travel.deliveryContactName,
                'delivery_contact_phone': userData.travel.deliveryContactPhone,
                'payment_type_id': PaymentType.CREDIT_CARD
            }, (data) => {
                // On envoi le paiement à stripe
                let location = window.location;
                let callbackUrl = location.protocol + '//' + location.hostname + ':' + location.port + '/valet';

                // console.log("confirmPayment!");

                stripe.confirmPayment({
                        elements,
                        clientSecret: data.client_secret,
                        confirmParams: {
                            return_url: callbackUrl,
                        }
                    }
                )
            }, (failResultData) => {
                Swal.fire({
                    text: failResultData.message,
                    icon: 'error',
                });
            });
        });
    }


    if (show) {
        return <div className="h-full flex flex-col p-4 gap-4">
            <StepperCar value={90}/>
            <div className="flex flex-nowrap justify-between items-center">
                <div className="text-white text-[28px] font-bold">Paiement CB</div>
                <Price value={valetIntent.price}/>
            </div>
            <fieldset className="border border-solid border-gray-300 p-3 rounded-2xl mt-4">
                <legend className="text-sm">&nbsp;DETAILS&nbsp;</legend>
                <PaymentElement id="payment-element" options={{
                    layout: 'tabs',
                    paymentMethodOrder: ['card']
                }}/>
            </fieldset>
            <div className="grow">
            </div>

            <Button text="Payer" clickHandler={payClickedHandler}/>
            <Button outline text="Précédent" clickHandler={(e) => setStep(ValetSteps.SUMMARY)}/>
        </div>
    } else {
        return null;
    }

}