import React, {useEffect, useState} from "react";
import Button from "../../components/v2/Button";
import StepperCar from "../../components/v2/StepperCar";
import Input from "../../components/v2/Input";
import useValetStore from "./hooks/useValetStore";
import Swal from "sweetalert2";
import Alert, {ERROR, SUCCESS} from "../../components/v2/Alert";
import {Link} from "react-router-dom";

export default function ValetYourEmail() {
    const {
        callPost,
        valetIntent,
        userData
    } = useValetStore();

    const [email, setEmail] = useState(userData?.email ?? '');
    const [emailSent, setEmailSent] = useState(false);
    const [alreadyExistError, setAlreadyExistError] = useState(false);

    const formRef = React.useRef();

    const nextClickedHandler = (e) => {
        if (!formRef.current.reportValidity()) {
            return;
        }
        callPost('quick_register', {
            user: {
                'email': email,
                'valet_intent_id': valetIntent.id,
                'language': 'fr' // TODO
            }
        }, (data) => {
            setEmail(email)
            setEmailSent(true)
        }, (failResultData) => {
            if (failResultData.message == 'register.error.email_already_exists') {
                setAlreadyExistError(true)
            }
        });
    }

    return <div className="h-full flex flex-col p-4 gap-4">
        <StepperCar value={30}/>
        <div className="text-white text-[30px] font-bold">
            Création d'un compte
        </div>

        Avant de pouvoir continuer votre commande, vous avez besoin d'un compte KeepUpCar.<br/><br/>

        Merci de renseigner une adresse électronique qui vous servira d'identifiant de compte.

        <form ref={formRef} className="flex flex-col gap-4">
            <Input id="from_address" label="Adresse électronique associée au compte"
                   placeholder="Exemple : m.jackson@gmail.com"
                   value={email}
                   required={true}
                   onChange={(e) => setEmail(e)}
            />
            {emailSent ?
                <Alert type={SUCCESS}
                       label="Mail de confirmation envoyé"
                       text="Cliquez sur le lien de confirmation reçu svp."
                />
                : null}
            {alreadyExistError ?
                <Link to="/login">
                    <Alert type={ERROR}
                           label="Adresse déjà utilisée !"
                           text="Cliquez ici pour vous connecter au compte associé."
                    />
                </Link>
                : null}
        </form>

        <div className="grow">
        </div>
        {!emailSent ?
            <Button text="Suivant" clickHandler={nextClickedHandler}/>
            : null}
    < /div>
}