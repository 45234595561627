import React, {useEffect, useState} from 'react';
import {ThemeProvider, createTheme, css} from '@mui/material/styles';
import {Button, Container, CssBaseline, Grid, Typography} from "@mui/material";
import {Link, Navigate, Outlet, Route, Routes, useLocation, useNavigate, useOutlet} from "react-router-dom";
import "./App.css";
import useMediaQuery from '@mui/material/useMediaQuery';
import {MobileProvider} from './contexts/mobileContext';
import {ConfigurationProvider} from './contexts/configurationContext';
import ApplicationSettingsService from "./services/ApplicationSettingsService";
import {useSelector} from "react-redux";
import BrandedKucFooter from "./features/branded/BrandedKucFooter";
import {getIsBranded} from "./features/branded/locationGroupSlice";
import BrandedContainer from "./features/branded/BrandedContainer";
import LanguageSelector from "./components/LanguageSelector";
import useLocalStorageState from "use-local-storage-state";
import ValetContainer from "./features/valet/ValetContainer";
import useValetStore from "./features/valet/hooks/useValetStore";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        yellow: {
            main: '#fbc70f',
            dark: '#ff9c0d',
            contrastText: '#000000',
        },
        red: {
            main: '#8b0000',
            dark: '#800000',
            contrastText: '#FFFFFF',
        },
        grey: {
            main: '#1C2326',
            dark: '#1C2326',
            contrastText: '#FFFFFF',
        }
    }
});

function App() {
    const apiUri = process.env.REACT_APP_API_URI;
    const [accessToken, setAccessToken] = useLocalStorageState('accessToken');

    const location = useLocation();
    const isMobile = useMediaQuery('(max-width:600px)');    // 600 = sm
    const outlet = useOutlet();

    const [applicationSettings, setApplicationSettings] = useState(null);

    const isBranded = useSelector(getIsBranded);
    const brand = useSelector(state => state.locationGroup.slug);

    const fullWidth = location.pathname == '/service_order_b';

    const isValetContainer = location.pathname == '/valet';

    // useEffect(() => {
    //     console.log('applicationSettings', applicationSettings);
    // }, [applicationSettings]);

    useEffect(() => {
        useValetStore.setState({
            servicesBaseUrl: apiUri,
        });
    }, []);

    function isComponentType(element, component) {
        return React.isValidElement(element) && element.type === component;
    }

    // Redirect
    if (location.pathname == '/') {
        if (accessToken != null) {
            return (<Navigate to='/profile'/>)
        } else {
            return (<Navigate to='/login'/>)
        }
    }

    let gridProps = {
        container: true,
        alignItems: 'center'
    };

    // Non centré
    switch (location.pathname) {
        // case '/service_order_b':
        case '/profile':
        case '/profile_stripe':
        case '/stripe_callback':
        case '/dashboard':
        case '/location':
        case '/location/add':
        case '/location/edit':
            gridProps = {};
            break;
    }
    if (location.pathname.startsWith('/dashboard')) {
        gridProps = {};
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <MobileProvider value={{
                isMobile: isMobile,
                debugStyle: {
                    border: "1px solid red"
                }
            }}>
                <ConfigurationProvider
                    value={applicationSettings}
                >
                    <ApplicationSettingsService apiUri={apiUri} setApplicationSettings={setApplicationSettings}/>
                    <Grid className="main_grid" {...gridProps} style={{
                        // backgroundColor: 'blue'
                    }}>
                        <CssBaseline/>
                        <Container
                            disableGutters={isMobile || isBranded}
                            maxWidth={fullWidth ? false : 'lg'}
                            style={{
                                height: isMobile ? '100%' : ''
                                // backgroundColor: 'green'
                            }}
                        >
                            {!isValetContainer ?
                                <LanguageSelector apiUri={[apiUri]}/>
                                : null
                            }
                            <Outlet context={[apiUri]}/>
                        </Container>

                        {/* NB : ne pas mettre le !important, sinon react vire tout le style */}
                        <div id="swal_container"
                             style={{
                                 position: "fixed",
                                 zIndex: "1400"
                             }}>
                        </div>
                    </Grid>
                </ConfigurationProvider>
            </MobileProvider>
        </ThemeProvider>
    )
        ;
}

export default App;