import React, {useEffect} from "react";
import Button from "../../components/v2/Button";
import StepperCar from "../../components/v2/StepperCar";
import Input from "../../components/v2/Input";
import IconFrom from "../../assets/v2/map-pin.png";
import IconTo from "../../assets/v2/goal.png";
import Dot from "../../assets/v2/dot.png";
import useValetStore from "./hooks/useValetStore";
import Swal from "sweetalert2";
import FormatUtils from "../../utils/FormatUtils";
import {RouteType} from "./constants/RouteType";

export default function ValetOurPrice({
                                          backClickedHandler,
                                          nextClickedHandler
                                      }) {

    const {
        valetIntent,
        userData
    } = useValetStore();

    const FormatGoogleAdress = (address) => {
        return address.split(',').map((it, i) => <div key={'x' + i}>{it}</div>)
    }

    return <div className="h-full flex flex-col p-4 gap-4">
        <StepperCar value={20}/>
        <div className="text-white text-[30px] font-bold">
            Notre meilleur tarif
        </div>

        <div className="flex flex-nowrap justify-center items-center">
            <div
                className="whitespace-nowrap rounded-3xl bg-[#00ae11] text-2xl font-bold py-2 px-7">{FormatUtils.formatAmount(valetIntent.price, true)}</div>
            <div className="px-4 text-lg">Pour un
                trajet <b>{userData.travel.routeType === RouteType.GOING ? 'ALLER' : "ALLET / RETOUR"} </b> de {FormatUtils.formatDistance(valetIntent.gmap_distance)}.
            </div>
        </div>

        <fieldset className="border border-solid border-gray-300 p-3 rounded-2xl mt-4">
            <legend className="text-sm">&nbsp;RECAPITULATIF&nbsp;</legend>
            <div className="flex">
                <div className="w-[32px] mr-3 flex flex-col justify-between items-center">
                    <img src={IconFrom} className="mx-auto max-w-xs"/>
                    <div style={{
                        backgroundImage: `url(${Dot})`,
                    }} className="h-full bg-repeat-y w-[24px] bg-center"></div>
                    <img src={IconTo} className="mx-auto max-w-xs"/>
                </div>
                <div className="grow">
                    <div><b>DEPART (COLLECTE)</b></div>
                    {FormatGoogleAdress(valetIntent.gmap_origin)}
                    <div className="mt-3"></div>
                    <div><b>DESTINATION (LIVRAISON)</b></div>
                    {FormatGoogleAdress(valetIntent.gmap_destination)}
                </div>
            </div>
        </fieldset>
        <div className="grow bg-white bg-opacity-10 rounded-2xl text-2xl flex justify-center items-center p-10">
            <i>2-3 slides conversion marketing (=Réassurance)</i>
        </div>
        <Button text="Continuer la commande" clickHandler={nextClickedHandler}/>
        <Button outline text="Modifier mon besoin" clickHandler={backClickedHandler}/>
    </div>
}