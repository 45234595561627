export class ValetSteps {
    static SPLASH = 'SPLASH';
    static YOUR_TRAVEL = 'YOUR_TRAVEL';
    static OUR_PRICE = 'OUR_PRICE';
    static YOUR_EMAIL = 'YOUR_EMAIL';
    static ACCOUNT_CREATED = 'ACCOUNT_CREATED';
    static YOUR_VEHICLE = 'YOUR_VEHICLE';
    static YOUR_IDENTITY = 'YOUR_IDENTITY';
    static YOUR_ORDER = 'YOUR_ORDER';
    static SUMMARY = 'SUMMARY';
    static PAYMENT_STRIPE = 'PAYMENT_STRIPE';
    static PAYMENT_INVOICE = 'PAYMENT_INVOICE';
    static ORDER_DONE = 'ORDER_DONE';
}

Object.freeze(ValetSteps);