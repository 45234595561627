import React, {useEffect, useState} from "react";
import useValetStore from "./hooks/useValetStore";
import {ValetSteps} from "./constants/ValetSteps";
import ValetSplash from "./ValetSplash";
import ValetYourTravel from "./ValetYourTravel";
import Spinner from "../../components/v2/Spinner";
import ValetOurPrice from "./ValetOurPrice";
import ValetYourEmail from "./ValetYourEmail";
import ValetAccountCreated from "./ValetAccountCreated";
import ValetYourVehicle from "./ValetYourVehicle";
import ValetYourIdentity from "./ValetYourIdentity";
import ValetYourOrder from "./ValetYourOrder";
import ValetSummary from "./ValetSummary";
import ValetPaymentInvoice from "./ValetPaymentInvoice";
import ValetOrderDone from "./ValetOrderDone";
import useLocalStorageState from "use-local-storage-state";
import Swal from "sweetalert2";
import ValetPaymentStripeWrapper from "./ValetPaymentStripeWrapper";
import {useSearchParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";

export default function ValetContainer() {
    const [accessToken, setAccessToken] = useLocalStorageState('accessToken');
    const [searchParams, setSearchParams] = useSearchParams();

    const [waitingForStatus, setWaitingForStatus] = useState(false);
    const navigate = useNavigate();

    const {
        step,
        setStep,
        loading,
        callGet,
        servicesBaseUrl,
        setStatusData,
        userData,
        setAccessToken: setAccessTokenStore,
    } = useValetStore();

    useEffect(() => {
        // On prend l'access token du local storage pour le mettre dans le store
        setAccessTokenStore(accessToken)
    }, [accessToken])


    useEffect(() => { // On a un access token => on détermine l'étape à afficher
        if (step === ValetSteps.ACCOUNT_CREATED) {
            // NB : c'est le seul cas ou on a besoin de récupérer l'intent
            getValetStatus();
        }
        // Sinon on laisse le flux habituel
    }, [step])

    useEffect(() => {
        // console.log("searchParams", searchParams.get('payment_intent'));
        if (searchParams.get('payment_intent') != null) {
            setStep(ValetSteps.PAYMENT_STRIPE);
        } else {
            if (step == null) {
                setStep(ValetSteps.SPLASH);
            }
        }
    }, [searchParams]);

    // Maintenant qu'on a un token, on récupère les infos déjà rentrées sur le trajet
    const getValetStatus = () => {
        callGet('valet_only_status', (successData) => {
            setStatusData(successData);
            // En fonction des data on regarde à quelle étape on va ( si on a pas d'étape défini )
            if (successData.account.has_password) {
                setStep(ValetSteps.YOUR_VEHICLE);
            } else {
                setStep(ValetSteps.ACCOUNT_CREATED);
            }

        }, (failResultData) => {
            Swal.fire({
                text: failResultData.message,
                icon: 'error',
            });
        });
    }

    const orderClickedHandler = () => {
        // console.log("orderClickedHandler")
        setStep(ValetSteps.YOUR_TRAVEL);
    }

    const ourPriceBackClickedHandler = () => {
        setStep(ValetSteps.YOUR_TRAVEL);
    }

    const yourEmailBackClickedHandler = () => {
        setStep(ValetSteps.OUR_PRICE);
    }
    const ourPriceNextClickedHandler = () => {
        // Si on a pas de compte ( donc pas d'access token ) , on passe à la création de compte
        if (accessToken == null) {
            setStep(ValetSteps.YOUR_EMAIL)
        } else {
            getValetStatus();
        }
    }

    const yourEmailNextClickedHandler = () => {
        setStep(ValetSteps.ACCOUNT_CREATED);
    }

    const accountCreatedNextClickedHandler = () => {
        setStep(ValetSteps.YOUR_VEHICLE);
    }

    const yourVehicleBackClickedHandler = () => {
        setStep(ValetSteps.YOUR_TRAVEL);
    }

    const yourVehicleNextClickedHandler = () => {
        setStep(ValetSteps.YOUR_IDENTITY);
    }

    const yourIdentityBackClickedHandler = () => {
        setStep(ValetSteps.YOUR_VEHICLE);
    }

    const yourIdentityNextClickedHandler = () => {
        setStep(ValetSteps.YOUR_ORDER);
    }

    const yourOrderBackClickedHandler = () => {
        setStep(ValetSteps.YOUR_IDENTITY);
    }

    const yourOrderNextClickedHandler = () => {
        setStep(ValetSteps.SUMMARY);
    }

    const summaryBackClickedHandler = () => {
        setStep(ValetSteps.YOUR_ORDER);
    }

    const invoiceNextClickedHandler = () => {
        setStep(ValetSteps.ORDER_DONE);
    }

    const orderDoneNextHandler = () => {
        // On envoi vers la liste des commandes
        navigate('/dashboard');
    }

    return <div className="h-full font-DMSans">
        {loading > 0 ? <div
            className="justify-center align-center bg-gray-800 bg-opacity-90 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 items-center w-full md:inset-0 h-full max-h-full flex ">
            <Spinner/>
        </div> : null}
        {step === ValetSteps.SPLASH ? <ValetSplash orderClickedHandler={orderClickedHandler}/> : null}
        {step === ValetSteps.YOUR_TRAVEL ? <ValetYourTravel orderClickedHandler={orderClickedHandler}/> : null}
        {step === ValetSteps.OUR_PRICE ? <ValetOurPrice backClickedHandler={ourPriceBackClickedHandler}
                                                        nextClickedHandler={ourPriceNextClickedHandler}/> : null}
        {step === ValetSteps.YOUR_EMAIL ? <ValetYourEmail nextClickedHandler={yourEmailNextClickedHandler}/> : null}
        {step === ValetSteps.ACCOUNT_CREATED ?
            <ValetAccountCreated nextClickedHandler={accountCreatedNextClickedHandler}/> : null}
        {step === ValetSteps.YOUR_VEHICLE ? <ValetYourVehicle backClickedHandler={yourVehicleBackClickedHandler}
                                                              nextClickedHandler={yourVehicleNextClickedHandler}/> : null}
        {step === ValetSteps.YOUR_IDENTITY ? <ValetYourIdentity backClickedHandler={yourIdentityBackClickedHandler}
                                                                nextClickedHandler={yourIdentityNextClickedHandler}/> : null}
        {step === ValetSteps.YOUR_ORDER ? <ValetYourOrder backClickedHandler={yourOrderBackClickedHandler}
                                                          nextClickedHandler={yourOrderNextClickedHandler}/> : null}
        {step === ValetSteps.SUMMARY ? <ValetSummary backClickedHandler={summaryBackClickedHandler}/> : null}
        {step === ValetSteps.PAYMENT_STRIPE ? <ValetPaymentStripeWrapper searchParams={searchParams}/> : null}
        {step === ValetSteps.PAYMENT_INVOICE ?
            <ValetPaymentInvoice nextClickedHandler={invoiceNextClickedHandler}/> : null}
        {step === ValetSteps.ORDER_DONE ? <ValetOrderDone nextHandler={orderDoneNextHandler}/> : null}
    </div>
}