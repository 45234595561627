import React, {useEffect, useState} from 'react';
import {useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    Box, Chip, Grid, Link,
    Paper, Stack,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography
} from "@mui/material";
import FormatUtils from "../utils/FormatUtils";
import BtnServiceDone from "../assets/buttons/service_done/car-alert-green.png";
import BtnOrderCard from "../assets/buttons/service_history/checkup-whitw-yewllow.png";
import CommentIcon from '@mui/icons-material/Comment';
import DescriptionIcon from '@mui/icons-material/Description';
import WarningIcon from '@mui/icons-material/Warning';
import ImageButton from "./ImageButton";
import moment from "moment";
import useFetch from "../hooks/useFetch";
import {PaymentType} from "../constants/PaymentType";
import {OrderStatus} from "../constants/OrderStatus";
import LeasingIcon from '../assets/leasing-icon.png';
import {ProviderOrderListType} from "../constants/ProviderOrderListType";

export default function ProviderOrderList({
                                              location,
                                              tabIndex,
                                              orderStatusId,
                                              excludeOrderStatusId,
                                              lastDoneOrder,
                                              searchText,
                                              showOrderCardHandler,
                                              serviceDoneClickedHandler,
                                              countChangedHandler
                                          }) {
    const {t, i18n} = useTranslation('common');
    const [apiUri] = useOutletContext();

    const [loaded, setLoaded] = useState(false);
    const [orderList, setOrderList] = useState(null);

    const [showLimit, setShowLimit] = useState(3);
    const {callPost} = useFetch();

    useEffect(() => {
        // console.log('lastDoneOrder', lastDoneOrder)
        setShowLimit(3);
        getOrderList();
    }, [location, tabIndex, lastDoneOrder, searchText])

    async function getOrderList() {

        let data = {
            location_id: location.id,
        }

        if (orderStatusId != null) {
            data.order_status_id = orderStatusId;
        }

        // TODO Pu utilisé
        if (excludeOrderStatusId != null) {
            data.exclude_order_status_id = excludeOrderStatusId;
        }

        if (searchText != null) {
            data.search = searchText;
        }

        switch (tabIndex) {
            case 0 :    // Today
                const today = moment().format('YYYY-MM-DD');
                data.date_start = today;
                data.date_end = today;
                data.list_type = ProviderOrderListType.TODAY;
                break;
            case 1 :    // Tomorrow
                const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
                data.date_start = tomorrow;
                data.date_end = tomorrow;
                data.list_type = ProviderOrderListType.TOMORROW;
                break;
            case 2: // This week
                data.date_start = moment().format('YYYY-MM-DD');
                data.date_end = moment().add(6, 'days').format('YYYY-MM-DD');
                data.list_type = ProviderOrderListType.THIS_WEEK;
                break;
            case 3: // All
                data.list_type = ProviderOrderListType.ALL;
                break;
            case 4: // Archives
                data.list_type = ProviderOrderListType.ARCHIVED;
                data.is_archive = 1;
                break;
        }

        callPost(apiUri + 'provider_order_list', data, resultData => {
            setLoaded(true);
            setOrderList(resultData.orders);
            countChangedHandler(resultData.orders.length);
        });
    }

    function showMoreClickedHandler() {
        setShowLimit(orderList.length);
    }

    function moreCount() {
        const count = orderList.length - showLimit;
        return count;
    }

    const cellStyle = {
        'color': 'black'
    }

    return (
        <Box minHeight={400}>
            {loaded &&
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>&nbsp;</TableCell>
                            <TableCell>{t('provider_order_book.order_number_label').toUpperCase()}</TableCell>
                            <TableCell>{t('provider_order_book.date_label').toUpperCase()}</TableCell>
                            <TableCell>{t('provider_order_book.vehicle_brand_label').toUpperCase()}</TableCell>
                            <TableCell>{t('provider_order_book.vehicle_model_label').toUpperCase()}</TableCell>
                            <TableCell>{t('provider_order_book.vehicle_plate_label').toUpperCase()}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderList.slice(0, showLimit).map((orderData, i) => {
                            let lineStyle = {};
                            if (orderData.provider_read_at == null) {
                                lineStyle.fontWeight = "bold";
                            }

                            return (<TableRow
                                key={i}
                                sx={{
                                    '&:last-child td, &:last-child th': {border: 0}
                                }}
                                style={i % 2 ? {
                                    background: "lightblue"
                                } : {
                                    background: "white"
                                }}
                            >
                                <TableCell>
                                    <Stack
                                        spacing={1}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        {
                                            orderData.new_message_count > 0 &&
                                            <Tooltip title={FormatUtils.changeTextWithValues(
                                                t('provider_order_book.new_event_chip_tooltip'),
                                                {
                                                    'count': orderData.new_message_count
                                                }
                                            )} arrow>
                                                <Chip
                                                    color="info"
                                                    size="small"
                                                    label={orderData.new_message_count}
                                                />
                                            </Tooltip>
                                        }

                                        {orderData.customer_comment != null &&
                                        <Tooltip title={orderData.customer_comment} arrow>
                                            <CommentIcon style={{'color': '#000000'}}/>
                                        </Tooltip>
                                        }
                                        {orderData.payment_type_id == PaymentType.QUOTATION &&
                                        <Tooltip title={t('provider_order_book.is_quotation')} arrow>
                                            <DescriptionIcon style={{'color': '#000000'}}/>
                                        </Tooltip>
                                        }
                                        {orderData.order_status_id == OrderStatus.QUOTATION_NEEDED &&
                                        <Tooltip title={t('provider_order_book.quotation_needed')} arrow>
                                            <WarningIcon style={{'color': '#FF0000'}}/>
                                        </Tooltip>
                                        }
                                        {orderData.order_status_id == OrderStatus.DISPUTE_BY_CUSTOMER &&
                                        <Tooltip title={t('provider_order_book.tooltip_dispute')} arrow>
                                            <WarningIcon style={{'color': '#FF0000'}}/>
                                        </Tooltip>
                                        }
                                        {
                                            orderData.leasing_company != null &&
                                            <Tooltip
                                                title={FormatUtils.changeTextWithValues(t('service_card.leasing_label'), {
                                                    leasing_company: orderData.leasing_company.label,
                                                    leasing_contract_number: orderData.leasing_contract_number
                                                })}
                                                arrow>
                                                <img src={LeasingIcon} style={{
                                                    height: 'auto',
                                                    width: '30px',
                                                    marginRight: '10px'
                                                }}/>
                                            </Tooltip>
                                        }
                                    </Stack>
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    style={cellStyle}
                                >
                                    <span style={lineStyle}>{orderData.number}</span>
                                </TableCell>
                                <TableCell
                                    style={{...cellStyle, ...lineStyle}}>
                                    {FormatUtils.formatServiceDateWithTimeSlot(orderData, i18n.language, t)}
                                    <Typography
                                        fontSize={12}
                                        fontWeight={orderData.lateDays > 0 ? "bold" : ""}
                                        color={orderData.lateDays > 0 ? "#FF0000" : ""}
                                    >{t("provider_order_book.retrieve_at_label")}{FormatUtils.formatSqlDateToCondensed(orderData.retrieve_at, i18n.language, true)}</Typography>
                                </TableCell>
                                <TableCell
                                    style={{...cellStyle, ...lineStyle}}>{orderData.vehicle.brand}</TableCell>
                                <TableCell
                                    style={{...cellStyle, ...lineStyle}}>{orderData.vehicle.model}</TableCell>
                                <TableCell
                                    style={{...cellStyle, ...lineStyle}}>{orderData.vehicle.licence_plate}</TableCell>
                                <TableCell style={cellStyle}>
                                    {orderData.order_status_id == OrderStatus.ACCEPTED &&
                                    <ImageButton src={BtnServiceDone} onClickHandler={(e) => {
                                        serviceDoneClickedHandler(orderData)
                                    }}/>
                                    }

                                    <ImageButton src={BtnOrderCard} onClickHandler={(e) => {
                                        showOrderCardHandler(orderData)
                                    }}/>

                                </TableCell>
                            </TableRow>)
                        })
                        }
                    </TableBody>
                </Table>
                {moreCount() > 0 &&
                <Grid
                    p={2}
                    container
                    style={{
                        // backgroundColor: "red"
                    }}
                    justifyContent="flex-end">
                    <Link
                        style={{
                            color: 'yellow'
                        }}
                        href="#"
                        onClick={showMoreClickedHandler}

                    >
                        {t("provider_order_book.show_more") + ' (' + moreCount() + ')'}
                    </Link>
                </Grid>
                }
            </TableContainer>}
        </Box>
    )
}