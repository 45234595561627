import React, {useEffect, useState} from "react";
import Button from "../../components/v2/Button";
import StepperCar from "../../components/v2/StepperCar";
import Input from "../../components/v2/Input";
import IconFrom from "../../assets/v2/map-pin.png";
import IconTo from "../../assets/v2/goal.png";
import Dot from "../../assets/v2/dot.png";
import useValetStore from "./hooks/useValetStore";
import Swal from "sweetalert2";
import {ValetSteps} from "./constants/ValetSteps";
import moment from "moment";

export default function ValetYourOrder({
                                           backClickedHandler,
                                       }) {

    const {
        valetIntent,
        setTravelData,
        setStep,
        userData
    } = useValetStore();

    const [date, setDate] = useState(userData?.travel?.date ?? moment().format('YYYY-MM-DD'));
    const [time, setTime] = useState(userData?.travel?.time ?? '');
    const [retrieveHour, setRetrieveHour] = useState(userData?.travel?.retrieveHour ?? '');
    const [phone, setPhone] = useState(userData?.travel?.phone ?? '');
    const [comment, setComment] = useState(userData?.travel?.comment ?? '');
    const [deliveryContactName, setDeliveryContactName] = useState(userData?.travel?.deliveryContactName ?? '');
    const [deliveryContactPhone, setDeliveryContactPhone] = useState(userData?.travel?.deliveryContactPhone ?? '');

    const formRef = React.useRef();

    const nextClickedHandler = (e) => {
        if (!formRef.current.reportValidity()) {
            return;
        }
        // NB on ne sauvegarde rien pour le moment, ce sera plus tard
        setTravelData(
            {
                date: date,
                time: time,
                retrieveHour: retrieveHour,
                phone: phone,
                comment: comment,
                deliveryContactName: deliveryContactName,
                deliveryContactPhone: deliveryContactPhone
            }
        )
        setStep(ValetSteps.SUMMARY);
    }

    return <div className="h-full flex flex-col p-4 gap-4">
        <StepperCar value={70}/>
        <div className="text-white text-[30px] font-bold">
            Pour votre commande...
        </div>
        <form ref={formRef}>
            <fieldset className="border border-solid border-gray-300 p-3 rounded-2xl mt-4">
                <legend className="text-sm">&nbsp;COLLECTE DU VEHICULE&nbsp;</legend>
                <Input id="date"
                       label="Date"
                       type="date"
                       placeholder="Exemple : 18/03/2025"
                       value={date}
                       required={true}
                       onChange={(e) => setDate(e)}
                />
                <Input id="hour" label="Heure" placeholder="Exemple : 16h45"
                       type="time"
                       value={time}
                       required={true}
                       onChange={(e) => setTime(e)}
                />
                <Input id="retrieveHour" label="Heure maximum du retour" placeholder="Exemple : 23h15"
                       type="time"
                       value={retrieveHour}
                       required={true}
                       onChange={(e) => setRetrieveHour(e)}
                />
                <Input id="phone" label="Contact téléphonique" placeholder="Exemple : 06.12.34.56.78"
                       value={phone}
                       required={true}
                       onChange={(e) => setPhone(e)}
                />
                <Input id="comment" label="Information éventuelle pour le voiturier"
                       placeholder="Exemple : Appelez-moi 5min avant"
                       value={comment}
                       onChange={(e) => setComment(e)}
                />
            </fieldset>

            <fieldset className="border border-solid border-gray-300 p-3 rounded-2xl mt-4">
                <legend className="text-sm">&nbsp;CONTACT POUR LA LIVRAISON&nbsp;</legend>
                <Input id="deliveryContactName" label="Nom de la personne et/ou de l'entreprise"
                       placeholder="Exemple : CARGLASS Reims"
                       value={deliveryContactName}
                       required={true}
                       onChange={(e) => setDeliveryContactName(e)}
                />
                <Input id="deliveryContactPhone" label="Numéro de téléphone" placeholder="Exemple : 06.12.34.56.78"
                       value={deliveryContactPhone}
                       required={true}
                       onChange={(e) => setDeliveryContactPhone(e)}
                />
            </fieldset>
        </form>
        <div className="grow">
        </div>
        <div className="flex gap-4">
            <Button outline text={<div>
                <div>Précédent</div>
                <div className="text-sm mt-[-8px]">(identité)</div>
            </div>} clickHandler={backClickedHandler}/>
            <Button text="Suivant" clickHandler={nextClickedHandler}/>
        </div>
    </div>
}