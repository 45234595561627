import React, {useEffect, useState} from "react";
import Button from "../../components/v2/Button";
import StepperCar from "../../components/v2/StepperCar";
import Input from "../../components/v2/Input";
import IconFrom from "../../assets/v2/map-pin.png";
import IconTo from "../../assets/v2/goal.png";
import Dot from "../../assets/v2/dot.png";
import useValetStore from "./hooks/useValetStore";
import Swal from "sweetalert2";
import {ValetSteps} from "./constants/ValetSteps";
import RadioSelector from "../../components/v2/RadioSelector";
import {RouteType} from "./constants/RouteType";

export default function ValetYourTravel({}) {

    const {
        callPost,
        userData,
        setTravelData
    } = useValetStore();

    const [fromAddress, setFromAddress] = useState(userData?.travel?.fromAddress ?? "");
    const [fromCp, setFromCp] = useState(userData?.travel?.fromCp ?? "");
    const [fromCity, setFromCity] = useState(userData?.travel?.fromCity ?? "");

    const [toAddress, setToAddress] = useState(userData?.travel?.toAddress ?? "");
    const [toCp, setToCp] = useState(userData?.travel?.toCp ?? "");
    const [toCity, setToCity] = useState(userData?.travel?.toCity ?? "");

    const [routeType, setRouteType] = useState(userData?.travel?.routeType ?? RouteType.GOING);

    const formRef = React.useRef();

    const calculateClickedHandler = (e) => {
        if (!formRef.current.reportValidity()) {
            return;
        }
        callPost('valet_only_search', {
            'origin_address': fromAddress,
            'origin_zip_code': fromCp,
            'origin_city': fromCity,
            'destination_address': toAddress,
            'destination_zip_code': toCp,
            'destination_city': toCity,
            'address_from': fromAddress + ' ' + fromCp + ' ' + fromCity,
            'address_to': toAddress + ' ' + toCp + ' ' + toCity,
            'route_type': routeType
        }, (data) => {
            setTravelData(
                {
                    fromAddress: fromAddress,
                    fromCp: fromCp,
                    fromCity: fromCity,
                    toAddress: toAddress,
                    toCp: toCp,
                    toCity: toCity,
                    routeType: routeType
                }
            )
            useValetStore.setState({
                valetIntent: data.valet_intent,
                step: ValetSteps.OUR_PRICE
            });
        }, (failResultData) => {
            Swal.fire({
                text: failResultData.message,
                icon: 'error',
            });
        });
    }

    return <div className="h-full flex flex-col p-4 gap-4">
        <StepperCar value={10}/>
        <div className="text-white text-[30px] font-bold">
            Votre besoin ?
        </div>

        <form ref={formRef}>
            <RadioSelector version={"button"} id={"route_type"} value={routeType} values={[
                {value: RouteType.GOING, label: 'Aller Simple'},
                {value: RouteType.GOING_AND_COMING, label: 'Aller / Retour'},
            ]} onChange={(value) => setRouteType(value)}/>

            <div className="flex mt-3">
                <div className="w-[32px] mr-3 flex flex-col justify-center items-center">
                    <img src={IconFrom} className="mx-auto max-w-xs"/>
                    <div style={{
                        backgroundImage: `url(${Dot})`,
                    }} className="h-[calc(100%/2)]  bg-repeat-y w-[24px] bg-center"></div>
                    <img src={IconTo} className="mx-auto max-w-xs"/>
                </div>
                <div className="grow">
                    <fieldset className="border border-solid border-gray-300 p-3 rounded-2xl">
                        <legend className="text-sm">&nbsp;DEPART&nbsp;</legend>
                        <Input id="from_address"
                               label="Adresse"
                               placeholder="Exemple : 3 rue des Roses"
                               value={fromAddress}
                               required={true}
                               onChange={(e) => setFromAddress(e)}
                        />

                        <Input id="from_postal_code"
                               label="ZIP / Code Postal"
                               placeholder="Exemple : 67000"
                               value={fromCp}
                               required={true}
                               onChange={(e) => setFromCp(e)}
                        />
                        <Input id="from_city" label="Ville" placeholder="Exemple : Strasbourg"
                               value={fromCity}
                               required={true}
                               onChange={(e) => setFromCity(e)}
                        />
                    </fieldset>
                    <fieldset className="border border-solid border-gray-300 p-3 rounded-2xl mt-4">
                        <legend className="text-sm">&nbsp;DESTINATION&nbsp;</legend>
                        <Input id="to_address" label="Adresse" placeholder="Exemple : 3 rue des Roses"
                               value={toAddress}
                               required={true}
                               onChange={(e) => setToAddress(e)}
                        />
                        <Input id="to_postal_code" label="ZIP / Code Postal" placeholder="Exemple : 67000"
                               value={toCp}
                               required={true}
                               onChange={(e) => setToCp(e)}
                        />
                        <Input id="to_city" label="Ville" placeholder="Exemple : Strasbourg"
                               value={toCity}
                               required={true}
                               onChange={(e) => setToCity(e)}
                        />
                    </fieldset>
                </div>
            </div>
        </form>
        <div className="grow"></div>
        <Button text="Calculer le meilleur tarif" clickHandler={calculateClickedHandler}/>
    </div>
}