import React, {useEffect} from "react";
import IconTick from "../../assets/v2/tick.png";
import IconCross from "../../assets/v2/cross.png";
import {twMerge} from "tailwind-merge";

interface AlertProps {
}

export const INFO = 'info';
export const SUCCESS = 'success';
export const ERROR = 'error';

export default function Alert({
                                  label,
                                  text,
                                  type = INFO,
                                  closeHandler = null,
                                  ...rest // Collect remaining props
                              }: AlertProps) {

    let typeClass = "";
    let typeIcon = '';
    switch (type) {
        case SUCCESS:
            typeClass = "bg-[#00ae11]"
            typeIcon = IconTick;
            break;
        case ERROR :
            typeClass = "bg-[#cd311e]"
            typeIcon = IconCross;
            break;
    }

    return (
        <div>
            <div className={twMerge("p-4 mb-4 text-sm text-white rounded-lg flex", typeClass)}
                 role="alert">
                <div>
                    <img className="mr-4 mt-1" src={typeIcon} alt="car"/>
                </div>
                <div className="grow">
                    <div className=""><b>{label}</b></div>
                    <div className="">{text}</div>
                </div>
                {closeHandler ?
                    <div>
                        <img className="" src={IconCross} onClick={(e) => closeHandler()} alt="car"/>
                    </div>
                    : null}
            </div>
        </div>);
};