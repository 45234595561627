import React from "react";
import {twMerge} from 'tailwind-merge'

export default function Button({
                                   clickHandler,
                                   text,
                                   outline = false,
                                   className,
                                    ...rest
                               }) {

    var priveClassName = "bg-[#f9b712] text-black w-full h-12 rounded-[10px] font-bold text-lg";
    if(outline)
    {
        priveClassName = "bg-transparent border-2 border-[#f9b712] text-[#f9b712] w-full h-12 rounded-[10px] font-bold text-lg";
    }

    // merge classes : https://www.youtube.com/watch?v=tfgLd5ZSNPc
    return (
        <button
            {...rest}
            className={twMerge(className, priveClassName )}
            type="button"
            onClick={clickHandler}
        >
            {text}
        </button>
    );
}