import React, {useEffect, useState} from "react";
import Button from "../../components/v2/Button";
import StepperCar from "../../components/v2/StepperCar";
import IconFrom from "../../assets/v2/map-pin.png";
import IconTo from "../../assets/v2/goal.png";
import Dot from "../../assets/v2/dot.png";
import useValetStore from "./hooks/useValetStore";
import IconCarFront from "../../assets/v2/car-front.png";
import IconTimer from "../../assets/v2/timer.png";
import RadioSelector from "../../components/v2/RadioSelector";
import {PaymentType} from "../../constants/PaymentType";
import FormatUtils from "../../utils/FormatUtils";
import {RouteType} from "./constants/RouteType";
import moment from "moment";
import Price from "../../components/v2/Price";
import {ValetSteps} from "./constants/ValetSteps";

export default function ValetSummary({
                                         backClickedHandler,
                                     }) {
    const {
        setStep,
        valetIntent,
        userData,
        setUserData,
    } = useValetStore();

    const [paymentType, setPaymentType] = useState(userData.paymentType ?? PaymentType.CREDIT_CARD);

    const FormatGoogleAdress = (address) => {
        return address.split(',').map((it, i) => <div key={'x' + i}>{it}</div>)
    }

    const date = moment(userData.travel.date);
    const now = moment();
    const time = now.clone().set({
        hour: userData.travel.time.split(':')[0],
        minute: userData.travel.time.split(':')[1]
    });

    const retrieveTime = now.clone().set({
        hour: userData.travel.retrieveHour.split(':')[0],
        minute: userData.travel.retrieveHour.split(':')[1]
    });

    const nextClickedHandler = () => {
        setUserData({
            ...userData,
            ...{
                paymentType: paymentType
            }
        });
        if (paymentType === PaymentType.CREDIT_CARD) {
            setStep(ValetSteps.PAYMENT_STRIPE);
        } else {
            setStep(ValetSteps.PAYMENT_INVOICE);
        }
    }

    return <div className="h-full flex flex-col p-4 gap-4">
        <StepperCar value={80}/>
        <div className="flex flex-nowrap justify-between items-center">
            <div className="text-white text-[30px] font-bold">Récapitulatif</div>
            <Price value={valetIntent.price}/>
        </div>
        <div>
            Mise à disposition d'un voiturier pour un
            trajet <b>{userData.travel.routeType === RouteType.GOING ? 'ALLER' : "ALLET / RETOUR"}</b> de {FormatUtils.formatDistance(valetIntent.gmap_distance)}.
        </div>

        <div className="flex flex-nowrap justify-between items-center gap-3">
            <div className="flex-grow h-full rounded-2xl bg-[#cdcdcd] text-black flex items-center w-5/6">
                <div><img className="m-5" src={IconCarFront} alt="car"/></div>
                <div className="flex-grow"><b>{userData.vehicle.model}</b>
                    <br/>Immatriculation : {userData.vehicle.licence_plate}
                </div>
            </div>
            <div
                className="rounded-xl bg-white font-bold text-black flex flex-col justify-center items-center p-1 w-1/6">
                <div><b>{date.format('ddd').toUpperCase()}</b></div>
                <div className="text-red-600 text-4xl mt-[-8px] mb-[-6px]">{date.format('D')}</div>
                <div className="text-xs"><b>{date.format('MMM').toUpperCase()}</b></div>
            </div>
        </div>


        <fieldset className="border border-solid border-gray-300 p-3 rounded-2xl mt-4">
            <legend className="text-sm">&nbsp;TRAJET&nbsp;</legend>
            <div className="flex">
                <div className="w-[32px] mr-3 flex flex-col justify-between items-center">
                    <img src={IconFrom} className="mx-auto max-w-xs"/>
                    <div style={{
                        backgroundImage: `url(${Dot})`,
                    }} className="h-full bg-repeat-y w-[24px] bg-center"></div>
                    <img src={IconTo} className="mx-auto max-w-xs"/>
                </div>
                <div className="grow">
                    <div><b>DEPART - {time.format('HH')}H{time.format('mm')}</b></div>
                    {FormatGoogleAdress(valetIntent.gmap_origin)}
                    <div>Contact : {userData.travel.phone}</div>
                    <div className="mt-3"></div>
                    <div><b>DESTINATION</b></div>
                    {FormatGoogleAdress(valetIntent.gmap_destination)}
                    <div>Contact : {userData.travel.deliveryContactPhone}</div>
                </div>
            </div>
            <div className="flex mt-5 mb-3 text-[#f9b712]">
                <div className="w-[32px] mr-3"><img src={IconTimer} className="mx-auto max-w-xs"/></div>
                <div><b>HEURE MAX RETOUR : {retrieveTime.format('HH')}H{retrieveTime.format('mm')}</b></div>
            </div>
        </fieldset>
        <RadioSelector id={"route_type"} value={paymentType} values={[
            {value: PaymentType.CREDIT_CARD, label: 'Paiement CB'},
            {value: PaymentType.INVOICE, label: 'Paiement par facture'},
        ]} onChange={(value) => setPaymentType(value)}/>
        <div className="grow">
        </div>
        <Button text="Confirmer & payer" clickHandler={nextClickedHandler}/>
        <Button outline text="Modifier ma commande" clickHandler={backClickedHandler}/>
    </div>
}