import React from "react";
import {twMerge} from 'tailwind-merge'

interface InputProps {
    id: string;
    onChange: (value: string) => void;
}

export default function RadioSelector({
                                          id,
                                          value,
                                          values,
                                          onChange,
                                          version = 'simple', // button
                                          ...rest
                                      }: InputProps) {

    if (version === 'button') {
        return (
            <div className="rounded-xl border-white border-2 flex justify-center h-[40px]">
                {values.map((item, index) => {
                    let className = "";
                    if (item.value === value) {
                        className = "text-black bg-white"
                    }
                    let basis = 1 / values.length;  // Util pour que chaque item fasse la meme largeur, qq soit leur contenu
                    return (
                        <div
                            key={index}
                            className={twMerge(className, "font-bold grow basis-" + basis + " rounded-[8px] -m-[1px] flex justify-center items-center")}
                            onClick={(e) => onChange(item.value)}>
                            {item.label}
                        </div>
                    );
                })}
            </div>
        );
    }
    return (
        <div className="flex justify-center h-[40px]">
            {values.map((item, index) => {
                let className = "";
                let selected = (item.value === value);
                let basis = 1 / values.length;  // Util pour que chaque item fasse la meme largeur, qq soit leur contenu
                return (
                    <div
                        key={index}
                        className={twMerge(className, "font-bold grow basis-" + basis + " rounded-[8px] -m-[1px] flex justify-center items-center")}>
                        <input type={"radio"}
                               className="w-5 h-5 mr-1 cursor-pointer accent-gray-800"
                               checked={selected}
                               onChange={(e) => onChange(item.value)}
                               id={'value_' + item.value}
                        /> <label htmlFor={'value_' + item.value}>{item.label}</label>
                    </div>
                );
            })}
        </div>);
}