import React, {useEffect, useState} from "react";
import Button from "../../components/v2/Button";
import StepperCar from "../../components/v2/StepperCar";
import Input from "../../components/v2/Input";
import IconFrom from "../../assets/v2/map-pin.png";
import IconTo from "../../assets/v2/goal.png";
import Dot from "../../assets/v2/dot.png";
import useValetStore from "./hooks/useValetStore";
import Swal from "sweetalert2";
import {ValetSteps} from "./constants/ValetSteps";
import VehicleSelector from "../../components/VehicleSelector";

export default function ValetYourVehicle({
                                             backClickedHandler
                                         }) {

    const {
        callPost,
        userData,
        setVehicleData,
        setStep
    } = useValetStore();

    const [brand, setBrand] = useState(userData?.vehicle?.brand ?? '');
    const [model, setModel] = useState(userData?.vehicle?.model ?? '');
    const [plate, setPlate] = useState(userData?.vehicle?.licence_plate ?? '');

    // Selectionné dans la liste , null si nouveau
    const [vehicle, setVehicle] = useState(userData?.vehicle?.vehicle);

    const formRef = React.useRef();

    const [locked, setLocked] = useState(false);
    const [showAddVehicle, setShowAddVehicle] = useState(false);

    useEffect(() => {
        console.log("vehicle=", vehicle);
        if(vehicle == null)
        {
            setLocked(false);
            setBrand('');
            setModel('');
            setPlate('');
        }
        else
        {
            setLocked(true);
            setBrand(vehicle.brand);
            setModel(vehicle.model);
            setPlate(vehicle.licence_plate);
        }
    }, [vehicle])

    const nextClickedHandler = (e) => {
        if (!formRef.current.reportValidity()) {
            return;
        }
        callPost('vehicle_register_quick', {
            'brand': brand,
            'model': model,
            'licence_plate': plate,
            'vehicle_id': vehicle?.id
        }, (data) => {
            setVehicleData(
                {
                    brand: brand,
                    model: model,
                    licence_plate: plate,
                    vehicle: {id: data.vehicle_id}
                }
            )
            setStep(
                ValetSteps.YOUR_IDENTITY
            );
        }, (failResultData) => {
            Swal.fire({
                text: failResultData.message,
                icon: 'error',
            });
        });
    }

    const vehicleSelectedHandler = (vehicle) => {
        if (vehicle != null) {
            setVehicle(vehicle);
        } else {
            setVehicle(null);
        }
    }

    return <div className="h-full flex flex-col p-4 gap-4">
        <StepperCar value={50}/>
        <div className="text-white text-[30px] font-bold">
            Votre véhicule ?
        </div>

        <VehicleSelector
            vehicle={vehicle}
            vehicleSelectedHandler={vehicleSelectedHandler}
            forValetOnboarding={true}
            showAddButton={false}
            hasVehicleHandler={() => setShowAddVehicle(true)}
        />

        {showAddVehicle ? <Button text="Nouveau véhicule" clickHandler={
            () => {
                setVehicle(null);
                setLocked(false);
                setBrand('');
                setModel('');
                setPlate('');
            }
        }/> : null
        }

        <form ref={formRef}>
            <fieldset className="border border-solid border-gray-300 p-3 rounded-2xl mt-4">
                <legend className="text-sm">&nbsp;INFORMATIONS&nbsp;</legend>
                <Input id="brand" label="Constructeur" placeholder="Exemple : Renault"
                       value={brand}
                       required={true}
                       disabled={locked}
                       onChange={(e) => setBrand(e)}
                />
                <Input id="model" label="Modèle" placeholder="Exemple : Mégane III"
                       value={model}
                       required={true}
                       disabled={locked}
                       onChange={(e) => setModel(e)}
                />
                <Input id="plate" label="Numéro de plaque d'immatriculation" placeholder="Exemple : FH-246-LG"
                       value={plate}
                       required={true}
                       disabled={locked}
                       onChange={(e) => setPlate(e)}
                />
            </fieldset>
        </form>
        <div className="grow">
        </div>
        <div className="flex gap-4">
            <Button outline text={<div>
                <div>Précédent</div>
                <div className="text-sm mt-[-8px]">(départ/destination)</div>
            </div>} clickHandler={backClickedHandler}/>
            <Button text="Suivant" clickHandler={nextClickedHandler}/>
        </div>
    </div>
}