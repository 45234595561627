import {
    Button, Card,
    Grid, Paper,
    Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip,
    Typography
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import VehicleItemSection from "./VehicleItemSection";
import LocationLabel from "./LocationLabel";
import ServiceHistoryIcon from "../assets/buttons/service_history/checkup-white-black.png";
import Swal from "sweetalert2";
import {useOutletContext} from "react-router-dom";
import FormatUtils from "../utils/FormatUtils";
import useFetch from "../hooks/useFetch";
import WarningIcon from '@mui/icons-material/Warning';
import {OrderStatus} from "../constants/OrderStatus";
import MobileContext from "../contexts/mobileContext";

function ExpandMoreIcon() {
    return null;
}

// type = 'accepted', 'done'
export default function VehicleItemSectionServiceHistory({
                                                             vehicleData,
                                                             type,
                                                             showOrderCardHandler,
                                                             newOrderData,
                                                             forceExpandWithVehicleId
                                                         }) {
    const {t, i18n} = useTranslation('common');
    const [apiUri] = useOutletContext();

    const [loaded, setLoaded] = useState(false);
    const [orderList, setOrderList] = useState(null);

    const {callPost} = useFetch();

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    useEffect(() => {
        if (newOrderData != null) {
            // Il ya eu une nouvelle commande => on refresh la liste
            // console.log("NEW ORDER DATA ! ", newOrderData.vehicle_id, vehicleData.vehicleId)
            if (newOrderData.id == vehicleData.id) {
                setTimeout(vehicleOrderList, 3000); // 3s => le temps que le paiement soit bien passé
            }
        }
    }, [newOrderData]);

    useEffect(() => {
        // Expand
        //
        if (forceExpandWithVehicleId == vehicleData.id) {
            vehicleOrderList();
        }
    }, [forceExpandWithVehicleId])

    async function vehicleOrderList() {

        let data = {
            vehicle_id: vehicleData.id
        }

        if (type == 'accepted') {
            data.order_status_id = 1;

        }

        if (type == 'done') {
            data.order_status_id = 2;
        }

        callPost(apiUri + 'vehicle_order_list', data, resultData => {
            setLoaded(true);
            setOrderList(resultData.orders);
        });
    }

    function expandedHandler() {
        if (!loaded) {
            vehicleOrderList();
        }
    }

    return (
        <VehicleItemSection
            icon={ServiceHistoryIcon}
            label={
                type == 'accepted' ?
                    t('vehicle.item.service_history.label_accepted') : t('vehicle.item.service_history.label_done')
            }
            expandedHandler={expandedHandler}
            defaultExpanded={forceExpandWithVehicleId == vehicleData.id}
            detailContent={<>
                {loaded ? (orderList.length == 0 ? <>{t('vehicle.item.service_history.no_order')}</> :
                        isMobile ?
                            <Grid>
                                {orderList.map((order, i) => {
                                    return (<Card
                                        sx={{
                                            p: 1,
                                            mb: 1
                                        }}
                                        key={i}
                                        // sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <Grid>
                                            {
                                                order.order_status_id == OrderStatus.DISPUTE_BY_CUSTOMER &&
                                                <Tooltip title={t('vehicle.item.service_history.tooltip_dispute')}
                                                         arrow>
                                                    <WarningIcon style={{'color': '#FF0000'}}/>
                                                </Tooltip>
                                            }
                                            <Typography>
                                                {FormatUtils.changeTextWithValues(
                                                    t('vehicle.item.service_history.mobile_line_1'),
                                                    {
                                                        date: FormatUtils.formatSqlDateToCondensed(order.date, i18n.language),
                                                        slot: FormatUtils.timeOrTimeSlot(order, t)
                                                    }
                                                )}
                                                <LocationLabel
                                                    locationData={order.location}
                                                    locationGroupData={order.location_group}
                                                />
                                            </Typography>
                                            <Typography dangerouslySetInnerHTML={{
                                                __html: FormatUtils.changeTextWithValues(
                                                    t('vehicle.item.service_history.mobile_line_3'),
                                                    {
                                                        order_num: order.number,
                                                        order_amount: '<b style="color: orange">' + FormatUtils.formatAmount(order.amount, true) + '</b>'
                                                    }
                                                )
                                            }}/>
                                            <Typography>
                                                {FormatUtils.changeTextWithValues(
                                                    t('vehicle.item.service_history.mobile_line_2'),
                                                    {
                                                        service: order.service.name,
                                                        service_type: t('service_types.' + order.location.service_type_id)
                                                    }
                                                )}
                                            </Typography>
                                            <Grid container justifyContent="center">
                                                <Button
                                                    size="small"
                                                    type="submit"
                                                    variant="contained"
                                                    color="yellow"
                                                    sx={{
                                                        width: '90%',
                                                        mt: 1
                                                    }}
                                                    onClick={(e) => showOrderCardHandler(order)}
                                                >
                                                    {t('vehicle.item.service_history.detail_btn')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Card>)
                                })
                                }
                            </Grid>
                            :
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>{t('vehicle.item.service_history.date_label')}</TableCell>
                                            <TableCell>{t('vehicle.item.service_history.provider_label')}</TableCell>
                                            <TableCell>{t('vehicle.item.service_history.service_type_label')}</TableCell>
                                            <TableCell>{t('vehicle.item.service_history.service_label')}</TableCell>
                                            <TableCell>{t('vehicle.item.service_history.order_number_label')}</TableCell>
                                            <TableCell>{t('vehicle.item.service_history.amount_label')}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orderList.map((order, i) => {
                                            return (<TableRow
                                                key={i}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell>
                                                    {
                                                        order.order_status_id == OrderStatus.DISPUTE_BY_CUSTOMER &&
                                                        <Tooltip title={t('vehicle.item.service_history.tooltip_dispute')}
                                                                 arrow>
                                                            <WarningIcon style={{'color': '#FF0000'}}/>
                                                        </Tooltip>
                                                    }
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {FormatUtils.formatDayDate(order.date, i18n.language)}
                                                    <br/>{FormatUtils.timeOrTimeSlot(order, t)}
                                                </TableCell>
                                                <TableCell> <LocationLabel
                                                    locationData={order.location}
                                                    locationGroupData={order.location_group}
                                                /></TableCell>
                                                <TableCell>{t('service_types.' + order.location.service_type_id)}</TableCell>
                                                <TableCell>{order.service.name}</TableCell>
                                                <TableCell>{order.number}</TableCell>
                                                <TableCell>{FormatUtils.formatAmount(order.amount, true)}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        size="small"
                                                        type="submit"
                                                        variant="contained"
                                                        color="yellow"
                                                        sx={{
                                                            width: "200px"
                                                        }}
                                                        onClick={(e) => showOrderCardHandler(order)}
                                                    >
                                                        {t('vehicle.item.service_history.detail_btn')}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>)
                                        })
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>)
                    :
                    <>
                        {t('common.loading')}
                    </>
                }
            </>}
        />
    )
}