import React, {useEffect, useState} from "react";
import Button from "../../components/v2/Button";
import StepperCar from "../../components/v2/StepperCar";
import Input from "../../components/v2/Input";
import useValetStore from "./hooks/useValetStore";
import Alert, {ERROR, SUCCESS} from "../../components/v2/Alert";
import {ValetSteps} from "./constants/ValetSteps";
import Swal from "sweetalert2";

export default function ValetAccountCreated({}) {

    //!\ Attention il peut arriver ici n'importe quand, suffit qu'il ait cliqué sur le lien de confirmation

    const [password, setPassword] = useState('');
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState(null);

    const formRef = React.useRef();

    const {
        callPost,
        callGet,
        setStep,

    } = useValetStore();

    useEffect(() => {
        let errors = checkPassword();
        if (errors.length > 0) {
            setIsError(true);
            setMessage("Le mot de passe n'est pas valide : " + errors.join(", "));
        } else {
            setIsError(false);
            setMessage("Mot de passe parfait !")
        }
    }, [password]);

    const nextClickedHandler = (e) => {
        if (!formRef.current.reportValidity()) {
            return;
        }
        callPost('set_password', {password}, (data) => {
            setStep(ValetSteps.YOUR_VEHICLE);
        }, (failResultData) => {
            Swal.fire({
                text: failResultData.message,
                icon: 'error',
            });
        });
    }

    const checkPassword = () => {
        const errors = [];
        if (password.length < 10) {
            errors.push("trop court");
        }
        if (!/[A-Z]/.test(password)) {
            errors.push("il manque une majuscule");
        }
        if (!/[0-9]/.test(password)) {
            errors.push("il manque un chiffre");
        }
        return errors;
    }

    return <div className="h-full flex flex-col p-4 gap-4">
        <StepperCar value={40}/>
        <div className="text-white text-[30px] font-bold">
            Compte activé !
        </div>
        Veuillez saisir un mot de passe sécurisé afin de finaliser votre inscription KeepUpCar.
        <br/><br/>
        Votre mot de passe doit faire au moins 10 caractères, contenir une majuscule et un chiffre.
        <form ref={formRef}>
            <Input id="from_address" label="Mot de passe"
                   placeholder="Exemple : Abcdefg25$"
                   value={password}
                   type={'password'}
                   required={true}
                   onChange={(e) => setPassword(e)}
            />
        </form>
        {message ?
            <Alert type={isError ? ERROR : SUCCESS}
                // label="Mot de passe invalide"
                   text={message}
            />
            : null}
        <div className="grow">
        </div>
        <Button disabled={isError} text="Reprendre la commande" clickHandler={nextClickedHandler}/>
    </div>
}