import React, {useContext, useEffect, useState} from 'react';
import {
    Box, Grid, Link, Modal, Typography
} from "@mui/material";
import {useOutletContext, useNavigate} from "react-router-dom";

import btnClose from "../assets/buttons/close/close-nobackground.svg";
import btnCloseOver from "../assets/buttons/close/close-nobackground.svg";
import ImageButton from "./ImageButton";
import BtnServiceDone from "../assets/buttons/service_done/car-alert-green.png";
import BtnServiceIssue from "../assets/buttons/service_issue/car-warning-red.png";

import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import FormatUtils from "../utils/FormatUtils";
import MessageBox from "./MessageBox";
import ServiceActionModal from "./ServiceActionModal";
import useFetch from "../hooks/useFetch";
import {ServiceType} from "../constants/ServiceType";
import {AccountType} from "../constants/AccountType";
import {OrderStatus} from "../constants/OrderStatus";
import {PaymentType} from "../constants/PaymentType";
import {OrderAction} from "../constants/OrderAction";
import MobileContext from "../contexts/mobileContext";
import TextWithBr from "./TextWithBr";
import InfoIcon from "@mui/icons-material/Info";
import useAlert from "../hooks/useAlert";

// cartType = "customer" | "provider"
export default function ServiceCardModal({
                                             order,
                                             closeHandler,
                                             cardType,
                                             orderChangedHandler,
                                             serviceDoneClickedHandler,
                                             changeServiceStateClickedHandler
                                         }) {
    const [apiUri] = useOutletContext();
    const {t, i18n} = useTranslation('common');
    const accountType = localStorage.getItem('accountType');

    const [loaded, setLoaded] = useState(false);
    const [orderData, setOrderData] = useState(null);

    const [providerComments, setProviderComments] = useState([]);
    const [chatMessages, setChatMessages] = useState([]);

    const [showActionModal, setShowActionModal] = useState(false);
    const [actionModalActionList, setActionModalActionList] = useState(null);

    const {call, callPost} = useFetch();

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    const {showInfo} = useAlert();

    useEffect(() => {
        getOrder();
    }, [order])

    useEffect(() => {
        if (orderData != null) {
            if (isProviderCard()) {
                let pMessages = [];
                orderData.provider_private_messages.forEach((message) => {
                    pMessages.push(convertMessageToChatMessage(message, 'provider_private'));
                });
                setProviderComments(pMessages);
            }

            let cMessages = [];
            orderData.chat_messages.forEach((message) => {
                cMessages.push(convertMessageToChatMessage(message));
            });
            setChatMessages(cMessages);
        }
    }, [orderData])

    // useEffect(() => {
    //     console.log(chatMessages);
    // }, [chatMessages]);

    // TODO Même que celui de ServiceActionModal
    async function postOrderAction() {
        let data = {
            order_id: order.id,
            action: 'customer_dispute'
        }

        callPost(apiUri + 'order/action', data, (resultData => {
            Swal.fire({
                text: t('service_action.success'),
                icon: 'success',
                timer: 2000,
                target: document.getElementById('swal_container'),
            })
                .then((result) => {
                    closeHandler(true);
                })
        }));
    }

    function getTotalBloc() {
        if (orderData.valent_intent == null) {
            return <Typography>
                {FormatUtils.formatPaymentType(orderData.payment_type_id, t)} : {FormatUtils.formatAmount(orderData.amount, true)} {orderData.payment_type_id == PaymentType.QUOTATION ? t('service.step4.total_with_quotation') : ''}
            </Typography>
        } else {
            if (isCustomerCard()) {
                var totalLine = FormatUtils.formatPaymentType(orderData.payment_type_id, t) + ' : ' + FormatUtils.formatAmount(orderData.amount, true);
                if (orderData.payment_type_id == PaymentType.QUOTATION) {
                    totalLine += ' ' + t('service.step4.total_with_quotation');
                }
                totalLine += '\n' + t('service_card.valet_line_included') + ' (' + FormatUtils.formatDistance(orderData.valent_intent.gmap_distance) + ') : ' + FormatUtils.formatAmount(orderData.valent_intent.price, true);
            } else {
                var totalLine = FormatUtils.formatPaymentType(orderData.payment_type_id, t) + ' : ' + FormatUtils.formatAmount(orderData.amount_without_valet, true);
                if (orderData.payment_type_id == PaymentType.QUOTATION) {
                    totalLine += ' ' + t('service.step4.total_with_quotation');
                }
                totalLine += '\n' + t('service_card.valet_line_excluded') + ' (' + FormatUtils.formatDistance(orderData.valent_intent.gmap_distance) + ') : ' + FormatUtils.formatAmount(orderData.valent_intent.price, true);
            }
            return <TextWithBr text={totalLine}/>
        }
    }

    function convertMessageToChatMessage(message, forceType = null) {
        let type = null;
        if (forceType) {
            type = forceType;
        } else {
            if (message.type == 'system') {
                type = 'system';
            } else {
                let mine = (isProviderCard() ? (message.type == 'provider') : (message.type == 'customer'));
                if (mine) {
                    type = 'mine';
                } else {
                    type = 'their';
                }
            }
        }

        return {
            type: type,
            text: message.text,
            picture: message.picture
        }
    }

    function isCustomerCard() {
        return cardType == 'customer';
    }

    function isProviderCard() {
        return cardType == 'provider';
    }

    function newProviderCommentHandler(comment) {
        postMessage('provider_private', comment);
    }

    function newChatMessageHandler(text) {
        postMessage(isProviderCard() ? 'provider' : 'customer', text);
    }

    function uploadDocumentDoneHandler(message) {
        chatMessages.push(convertMessageToChatMessage(message))
        setChatMessages(FormatUtils.cloneData(chatMessages));
        // console.log('uploadDocumentDoneHandler', message);
    }

    function appendPrivateComment(comment) {
        providerComments.push(convertMessageToChatMessage(comment, 'provider_private'));
        setProviderComments(JSON.parse(JSON.stringify(providerComments)));
    }

    function appendChatMessage(message) {
        chatMessages.push(convertMessageToChatMessage(message))
        setChatMessages(JSON.parse(JSON.stringify(chatMessages)));
    }

    function closeOrderActionModal(refreshNeeded = false) {
        setShowActionModal(false);
        if (refreshNeeded) {
            orderChangedHandler(order);
            getOrder();
        }
    }

    function showOrderActionsClickedHandler() {
        setActionModalActionList(null);
        setShowActionModal(true);
    }

    function disputeLinkClickedHandler() {
        setActionModalActionList([OrderAction.CUSTOMER_DISPUTE]);
        setShowActionModal(true);
    }

    async function getOrder() {

        call(apiUri + 'order/' + order.id, (resultData => {
            setLoaded(true);
            setOrderData(resultData.order);
        }));
    }

    async function postMessage(type, text) {
        let data = {
            order_id: order.id,
            type: type,
            text: text
        }

        call(apiUri + 'order_message', (resultData => {
            switch (resultData.message.type) {
                case 'provider_private':
                    appendPrivateComment(resultData.message);
                    break;
                default :
                    appendChatMessage(resultData.message);
                    break;
            }
        }), 'POST', data, true);
    }


    function getServiceLabel() {
        let serviceLabel;
        if (accountType == AccountType.CUSTOMER) {
            serviceLabel = t('service_card.service_label');
        } else if (accountType == AccountType.PROVIDER) {
            serviceLabel = FormatUtils.changeTextWithValues(
                t('service_card.service_label_provider'),
                {
                    'customer_name': orderData.customer.first_name + ' ' + orderData.customer.last_name
                });
        }
        return serviceLabel;
    }

    return (
        <Modal
            open={true}
            style={{
                overflowY: 'scroll',
                marginBottom: isMobile ? '70px' : null
            }}
            disableEnforceFocus
        >
            <div style={isMobile ? style_mobile : style}>
                {loaded &&
                    <Box sx={styleInner}>
                        <Grid container justifyContent="flex-end"
                              sx={{
                                  backgroundColor: 'red'
                              }}
                        >
                            <Grid item sx={{
                                flexGrow: 1,
                                // backgroundColor: '#afbff0'
                            }} height="100%">
                                <Grid container justifyContent="center" textAlign="center" height="100%">
                                    <Typography fontSize={isMobile ? '20px' : '25px'}
                                                mt={isMobile ? '10px' : '10px'}
                                                fontWeight={"bold"}>{
                                        isCustomerCard() ?
                                            t('service_card.label_customer').toUpperCase() :
                                            t('service_card.label_provider').toUpperCase()
                                    }</Typography>
                                </Grid>
                            </Grid>
                            <Grid item display="flex" justifyContent="center" alignContent="center">
                                <ImageButton
                                    src={btnClose}
                                    srcOver={btnCloseOver}
                                    height={isMobile ? '40px' : '50px'}
                                    onClickHandler={() => {
                                        orderChangedHandler();
                                        closeHandler();
                                    }}/>
                            </Grid>
                        </Grid>
                        <Grid container flexDirection={isMobile ? 'column-reverse' : null}>
                            <Grid item xs={isMobile ? 12 : 6}>
                                <Box margin={isMobile ? 1 : 5}>
                                    <Typography mt={1}
                                                mb={1}
                                                fontSize={isMobile ? '15px' : '20px'}
                                                fontWeight="bold">
                                        {getServiceLabel()}
                                    </Typography>
                                    <Typography fontWeight="bold"
                                                color="#fbc70f"
                                        // fontSize={isMobile ? '0.8em' : null}
                                    >
                                        {FormatUtils.formatServiceDateWithTimeSlot(orderData, i18n.language, t)}</Typography>
                                    <Typography>{t('service_types.' + orderData.location.service_type_id).toUpperCase()} : {orderData.service.name}</Typography>
                                    {
                                        orderData.options.map((option, i) => {
                                            return <Typography
                                                key={i}>{t('service_card.option_label').toUpperCase()} : {option.name}</Typography>;
                                        })
                                    }
                                    {
                                        orderData.has_tire_storage == '1' &&
                                        <Typography>{t('service_card.tire_storage_label')}</Typography>
                                    }
                                    {
                                        orderData.location.service_type_id == ServiceType.TIRES &&
                                        <>
                                            <Typography fontWeight="bold">{t('service.step4.summary_tire')}</Typography>
                                            {orderData.tires.map((tire, i) => {
                                                return <Typography
                                                    key={i}>{FormatUtils.formatOrderTire(tire, t)}</Typography>
                                            })}
                                        </>
                                    }
                                    {
                                        orderData.quotation_customer_comment != null &&
                                        <>
                                            <Typography
                                                fontWeight="bold">{t('service_card.quotation_customer_comment')}</Typography>
                                            <Box
                                                sx={{
                                                    p: "2px",
                                                    border: 2,
                                                    borderRadius: '8px',
                                                    borderColor: 'orange',
                                                    display: 'grid',
                                                    gridTemplateRows: '1fr 40px',
                                                }}
                                                style={{
                                                    backgroundColor: 'grey',
                                                }}
                                            >
                                                <Box p={1} overflow="auto">
                                                    {orderData.quotation_customer_comment}
                                                </Box>
                                            </Box>
                                        </>
                                    }
                                    {
                                        orderData.customer_comment != null &&
                                        <>
                                            <Typography
                                                fontWeight="bold">{t('service_card.customer_comment')}</Typography>
                                            <Box
                                                sx={{
                                                    p: "2px",
                                                    border: 2,
                                                    borderRadius: '8px',
                                                    borderColor: 'orange',
                                                    display: 'grid',
                                                    gridTemplateRows: '1fr 40px',
                                                }}
                                                style={{
                                                    backgroundColor: 'grey',
                                                }}
                                            >
                                                <Box p={1} overflow="auto">
                                                    {orderData.customer_comment}
                                                </Box>
                                            </Box>
                                        </>
                                    }
                                    {isCustomerCard() &&
                                        <>
                                            <Typography mt={1} mb={1} fontSize={isMobile ? '15px' : '20px'}
                                                        fontWeight="bold">{t('service_card.provider_label')}</Typography>
                                            <Typography fontWeight="bold"
                                                        color="#fbc70f"> {orderData.location.name.toUpperCase()}</Typography>

                                            <Typography>{FormatUtils.timeOrScheduleTime(orderData, t)}</Typography>

                                            <Typography>{FormatUtils.formatAddress(orderData.location)}</Typography>
                                        </>
                                    }
                                    <Typography fontWeight="bold"
                                                color="#FF0000">{t('service_card.retrieve_at') + FormatUtils.formatRetrieveAt(orderData.retrieve_at, i18n.language)}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 6} sx={{
                                borderLeft: isMobile ? null : 1,
                                borderColor: isMobile ? null : 'orange'
                            }}>
                                <Box margin={isMobile ? 1 : 5}>
                                    <Grid container justifyContent="space-between">
                                        <Grid item>
                                            <Typography mt={1} mb={1} fontSize={isMobile ? '15px' : '20px'}
                                                        fontWeight="bold">{t('service_card.order_label')} N° {orderData.number}</Typography>
                                            <Typography
                                                component="span">{t('service_card.status_label')} : </Typography>
                                            <Typography component="span"
                                                        color={orderData.order_status_id == 1 ? "green" : null}>
                                                {
                                                    orderData.order_status_id == OrderStatus.QUOTATION_NEEDED && accountType == AccountType.CUSTOMER ?
                                                        t('service_card.status_customer_quotation_in_progress')
                                                        :
                                                        FormatUtils.formatOrderStatus(orderData.order_status_id, t)
                                                } </Typography>
                                            {orderData.last_status_at != null && orderData.order_status_id != OrderStatus.QUOTATION_NEEDED &&
                                                <Typography
                                                    component="span">{t('common.date_at')} {FormatUtils.formatSqlDateToCondensed(orderData.last_status_at, i18n.language)}</Typography>
                                            }
                                            {
                                                accountType == AccountType.PROVIDER &&
                                                <>
                                                    {orderData.order_status_id == OrderStatus.QUOTATION_NEEDED &&
                                                        <Link
                                                            ml={5}
                                                            href="#"
                                                            onClick={() => changeServiceStateClickedHandler(order, OrderStatus.QUOTATION_SENT)}
                                                        >
                                                            {t("service_card.quotation_sent_link")}
                                                        </Link>
                                                    }
                                                    {orderData.order_status_id == OrderStatus.QUOTATION_SENT &&
                                                        <>
                                                            <Link
                                                                ml={5}
                                                                href="#"
                                                                onClick={() => changeServiceStateClickedHandler(order, OrderStatus.ACCEPTED)}
                                                            >
                                                                {t("service_card.quotation_accepted_link")}
                                                            </Link>
                                                            <Link
                                                                ml={5}
                                                                href="#"
                                                                onClick={() => changeServiceStateClickedHandler(order, OrderStatus.QUOTATION_REFUSED)}
                                                            >
                                                                {t("service_card.quotation_refused_link")}
                                                            </Link>
                                                        </>
                                                    }
                                                </>
                                            }
                                            {getTotalBloc()}
                                            <Typography>{t('service_card.phone_label')} : {orderData.customer_phone}</Typography>
                                            {orderData.payment_type_id == PaymentType.LEASING_INVOICE &&
                                                <Typography>{FormatUtils.changeTextWithValues(t('service_card.leasing_label'),
                                                    {
                                                        'leasing_company': orderData.leasing_company.label,
                                                        'leasing_contract_number': orderData.leasing_contract_number
                                                    }
                                                )}</Typography>
                                            }
                                        </Grid>
                                        <Grid item>
                                            {
                                                isProviderCard() && orderData.order_status_id == OrderStatus.ACCEPTED &&
                                                <>
                                                    <ImageButton src={BtnServiceDone} height={75}
                                                                 onClickHandler={(e) => serviceDoneClickedHandler(orderData)}/>
                                                    <ImageButton src={BtnServiceIssue} height={75}
                                                                 onClickHandler={showOrderActionsClickedHandler}/>
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                    {isProviderCard() &&
                                        <>
                                            <br/>
                                            <Typography>{t('service_card.provider_comment')}</Typography>
                                            <MessageBox
                                                orderData={orderData}
                                                height={200}
                                                messages={providerComments}
                                                newMessageHandler={newProviderCommentHandler}
                                                isMobile={isMobile}
                                            >
                                            </MessageBox>
                                        </>
                                    }
                                </Box>
                            </Grid>
                        </Grid>

                        <Typography ml={isMobile ? 1 : 5} mt={1} mb={1} fontSize={isMobile ? '15px' : '20px'}
                                    fontWeight="bold">{t('service_card.chat_label')}</Typography>
                        <Box m={isMobile ? 1 : 5} mt={1}>
                            <MessageBox height={250}
                                        messages={chatMessages}
                                        newMessageHandler={newChatMessageHandler}
                                        orderData={orderData}
                                        withUpload={true}
                                        uploadDocumentDoneHandler={uploadDocumentDoneHandler}
                                        withVehicleDocuments={isCustomerCard()}
                                        isMobile={isMobile}
                            />
                        </Box>
                        {
                            [
                                OrderStatus.ACCEPTED,
                                OrderStatus.QUOTATION_NEEDED,
                                OrderStatus.QUOTATION_SENT]
                                .includes(orderData.order_status_id)
                            &&
                            <Grid
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Link
                                    ml={isMobile ? 1 : 5}
                                    href="#"
                                    color="#999999"
                                    onClick={showOrderActionsClickedHandler}
                                >
                                    {
                                        accountType == AccountType.CUSTOMER ?
                                            t("service_card.cancel_link")
                                            :
                                            t("service_card.cancel_link_provider")
                                    }
                                </Link>
                                <InfoIcon
                                    cursor="pointer"
                                    onClick={function (e) {
                                        showInfo('service_card.cancel_link_hint')
                                    }}
                                    sx={
                                        {
                                            marginLeft: '5px',
                                            // marginTop: '-1px',
                                            color: 'orange',
                                            fontSize: 28,
                                        }}/>
                            </Grid>
                        }
                        {accountType == AccountType.CUSTOMER &&
                            <Typography ml={isMobile ? 1 : 5}>{t('service_card.dispute_notice')}</Typography>
                        }
                        {accountType == AccountType.CUSTOMER && orderData.can_dispute == '1' &&
                            <Link
                                ml={isMobile ? 1 : 5}
                                href="#"
                                color="#999999"
                                onClick={disputeLinkClickedHandler}
                            >
                                {
                                    t("service_card.dispute_link")
                                }
                            </Link>
                        }
                    </Box>
                }
                {
                    showActionModal &&
                    <ServiceActionModal
                        actionList={actionModalActionList}
                        order={order}
                        closeHandler={closeOrderActionModal}/>
                }
            </div>
        </Modal>
    );
}

const style = {
    position: 'absolute',
    top: '2.5%',
    bottom: '2.5%',
    left: '2.5%',
    right: '2.5%',
};

const style_mobile = {
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
}

const styleInner = {
    bgcolor: 'background.paper',
    border: '2px solid #000',
    minHeight: '100%',
    // backgroundColor: 'blue'
}