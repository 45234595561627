import {Button, Container, FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useOutletContext} from "react-router-dom";
import useFetch from "../hooks/useFetch";
import VehicleModal from "./VehicleModal";

// Selecteur de vehicle du client + lien pour en créer un nouveau
// Inspiré de ServiceStep1 et VehicleIndex
export default function VehicleSelector({
                                            vehicle,
                                            vehicleSelectedHandler,
                                            isPreview,
                                            previewActionHandler,
                                            showAddButton = true,
                                            forValetOnboarding = false,
                                            hasVehicleHandler
                                        }) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');
    const [loaded, setLoaded] = useState(false);
    const [vehicleList, setVehicleList] = useState([]);
    const [localVehicle, setLocalVehicle] = useState(vehicle);
    const {call} = useFetch();

    const isMobile = true;

    const [showAdd, setShowAdd] = useState(false);  // Modal de création d'un véhicule


    useEffect(() => {
        vehicleIndexGet();
    }, []);

    useEffect(() => {
        vehicleSelectedHandler(localVehicle);
    }, [localVehicle])

    // TODO Meme que VehicleIndex
    async function vehicleIndexGet(selectedVehicleId = null) {
        call(apiUri + 'vehicle_index', (resultData => {
            setLoaded(true);
            // contentLoadedHandler();
            setVehicleList(resultData.vehicles);
            if (resultData.vehicles.length > 0) {
                hasVehicleHandler();
            }
            if (resultData.vehicles.length == 1) {
                setLocalVehicle(resultData.vehicles[0]);
            } else {
                //
                resultData.vehicles.forEach(
                    vehicle => {
                        if (vehicle.id == selectedVehicleId) {
                            setLocalVehicle(vehicle);
                        }
                    }
                )
            }
        }));
    }

    // Provient de la modale de création
    function vehicleUpdatedHandler(newVehicleId = null) {
        // console.log("vehicleUpdatedHandler!!! newVehicleId=", newVehicleId);
        setShowAdd(false);
        vehicleIndexGet(newVehicleId);
    }

    return (
        <Container
            sx={{
                mt: forValetOnboarding ? 0 : 2,
                mb: forValetOnboarding ? 0 : 2
            }}
            disableGutters={forValetOnboarding}
        >
            {loaded &&
                <>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                    >
                        {vehicleList.length == 0 ?
                            <>
                                {forValetOnboarding ?
                                    null :
                                    <h3>
                                        {t("service.step1.no_vehicle")}
                                    </h3>}
                            </>
                            :
                            <FormControl fullWidth sx={{
                                mt: 1
                            }}>
                                <InputLabel>{t('service.step2.vehicle_label')}</InputLabel>
                                <Select
                                    size="small"
                                    id="vehicle"
                                    label={t('service.step2.vehicle_label')}
                                    value={localVehicle ? localVehicle.id : ''}
                                    onChange={
                                        e => {
                                            // console.log(e.target.value);
                                            let vehicleId = e.target.value;
                                            let vehicle = vehicleList.find((elem => elem.id == vehicleId));
                                            setLocalVehicle(vehicle);
                                        }
                                    }
                                >
                                    {
                                        vehicleList.map((vehicle, i) => {
                                            return (<MenuItem key={i}
                                                              value={vehicle.id}>{vehicle.label} {vehicle.licence_plate}</MenuItem>);
                                        })
                                    }
                                </Select>
                            </FormControl>
                        }
                    </Grid>
                    {showAddButton ?
                        <Button
                            fullWidth
                            variant="contained"
                            color="yellow"
                            sx={{
                                mt: '2px'
                                // mt: '15px',
                                // mb: '20px',
                                // mr: isMobile ? '5px' : null,
                                // height: isMobile ? '60px' : null
                            }}
                            onClick={
                                () => {
                                    if (isPreview) {
                                        previewActionHandler();
                                    } else {
                                        setShowAdd(true);
                                    }
                                }
                            }
                        >
                            {t('vehicle.button_create_vehicle')}
                        </Button> : null
                    }
                </>
            }
            {showAdd &&
                <VehicleModal
                    standaloneAdd={true}
                    standaloneVehicleUpdatedHandler={vehicleUpdatedHandler}
                />
            }
        </Container>
    )
}