import React, {useEffect} from "react";
import logo from "../../assets/logo-header.png";
import Button from "../../components/v2/Button";
import {Link} from "react-router-dom";
import useValetStore from "./hooks/useValetStore";

export default function ValetSplash(
    {
        orderClickedHandler
    }
) {

    const {
        accessToken
    } = useValetStore();

    return <div className="h-full flex flex-col">
        <img src={logo} className="mt-4 mx-auto max-w-xs"/>
        <div className="bg-white mt-4">
            <div className="text-xl text-center text-[#060d25] italic uppercase font-bold">VOTRE SERVICE
                VOITURIER
            </div>
        </div>
        <div className="m-5 flex flex-col gap-4 h-full">
            <div className="grow bg-[#FFFFFF] bg-opacity-10 rounded-3xl h-auto text-white p-5 text-lg">
                🚗 1 – “Prise en charge” <br/>
                📍 État des lieux avant départ<br/>
                🔹 Photos, niveaux, pneus<br/>
                🔹 Voiturier professionnel<br/>
                ✅ Sécurité & transparence<br/><br/>
                🔍 2 – “Transport sécurisé”<br/>
                🛡 Suivi en temps réel<br/>
                🔹 Rapport envoyé après la prise en charge<br/>
                🔹 Livraison du véhicule à l’adresse prévue<br/>
                ✅ Fiable & sans surprise<br/><br/>
                ⏳ 3 – “À votre service”<br/>
                🌍 Livraison 7j/7, où vous voulez<br/>
                🔹 Respect des horaires<br/>
            </div>
            <Button text="Commander" clickHandler={orderClickedHandler}/>
            {accessToken === null ?
                <Link to="/login"> <Button outline text="Déjà client ?"/></Link>
                : null
            }
        </div>
    </div>
}