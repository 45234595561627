import React, {useEffect, useState} from "react";
import StepperCar from "../../components/v2/StepperCar";
import useValetStore from "./hooks/useValetStore";
import Logo from '../../assets/logo-header.png';
import Success from '../../assets/v2/success.png';
import Button from "../../components/v2/Button";

export default function ValetOrderDone({
                                           nextHandler
                                       }) {

    const {
        valetIntent
    } = useValetStore();

    return <div className="h-full flex flex-col p-4 gap-4">
        <StepperCar value={100}/>
        <div className="text-center">
            <img src={Logo} className="mt-4 mx-auto max-w-xs"/>
            <div className="mt-10">
                <img src={Success} className="mt-4 mx-auto max-w-xs"/>
            </div>
            <div className="text-white text-[30px] font-bold mt-4">
                Commande validée.
            </div>
            <div className="text-[#f9b712] text-[36px] font-bold mt-10">
                Merci !
            </div>
            <div className="text-white text-[24px] mx-5">
                Un récapitulatif de la commande vous été envoyé par email.
            </div>
        </div>
        <div className="grow">
        </div>
        <Button text="Aller à mes commandes" clickHandler={nextHandler}/>
    </div>
}